import React, { useCallback, useContext, useState } from 'react'
import { useEffect } from 'react';
import {AuthContext } from "../../../../store/auth"
import "../../Admin.css"
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const BikesAdmin = ({ setTotalPosts }) => {
  const [car, setCar] = useState([]);
  const { location, searchProduct, user } = useContext(AuthContext);
  const [currentPage, setCurrentPage] = useState(1);
  const bikesPerPage = 24; // Same as carsPerPage
  // const [products, setProducts] = useState([]);
  const navigate = useNavigate();

  const getCar = useCallback(async () => {
    try {
      const response = await fetch(`https://in.quiko.in/api/sell/bikes`, {
        method: "GET",
      });
      if (response.ok) {
        const data = await response.json();
        setCar(data.response_data);
        setTotalPosts(data.response_data.length);  // Set total number of posts
      }
    } catch (error) {
      console.log("service page error ", error);
    }
  }, [setTotalPosts]);

  useEffect(() => {
    getCar();
  }, [getCar]);

  const deleteBikes = useCallback(async (id, imageUrl) => {
    try {
      const response = await fetch(`https://in.quiko.in/api/sell/bikes/delet/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${user.token}`, // If your API requires token authentication
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ imageUrl })
      });
      if (response.ok) {
        setCar((prevProducts) => prevProducts.filter((product) => product._id !== id));
        toast.success("Delet successfull")

      } else {
        console.log("Failed to delete the car.");
      }
    } catch (error) {
      console.log("service page error ", error);
    }
  }, [user]);
  
  const filteredBikes = car.filter((curEle) => {
    const lowerLocation = location.toLowerCase();
    const lowerSearchProduct = searchProduct.toLowerCase();
    return (
      ((curEle.state && curEle.state.toLowerCase().includes(lowerLocation)) ||
        (curEle.aria && curEle.aria.toLowerCase().includes(lowerLocation))) &&
      ((curEle.title &&
        curEle.title.toLowerCase().includes(lowerSearchProduct)) ||
        (curEle.brand &&
          curEle.brand.toLowerCase().includes(lowerSearchProduct)))
    );
  }).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

  const indexOfLastBike = currentPage * bikesPerPage;
  const indexOfFirstBike = indexOfLastBike - bikesPerPage;
  const currentBikes = filteredBikes.slice(indexOfFirstBike, indexOfLastBike);

  const totalPages = Math.ceil(filteredBikes.length / bikesPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Pagination logic to show numbers with ellipsis when appropriate
  const getPaginationNumbers = () => {
    const pageNumbers = [];

    if (totalPages > 1) {
      if (currentPage === 1) {
        pageNumbers.push(1, 2, '...');
      } else if (currentPage === 2) {
        pageNumbers.push(1, 2, 3);
      } else if (currentPage >= 3) {
        pageNumbers.push(currentPage - 1, currentPage, '...');
      }
    } else if (totalPages === 1) {
      pageNumbers.push(1);
    }

    return pageNumbers;
  };

  const handleCardClick = (_id) => {
    navigate(`/bikes/${_id}`);
  };

  const handleEditClick = (carId) => {
    // Navigate to the edit form with the car ID
    navigate(`/edit/bikes/${carId}`);
  };

  const timeAgo = (dateString) => {
    const now = new Date();
    const postDate = new Date(dateString);
    const diffInSeconds = Math.floor((now - postDate) / 1000);
  
    const seconds = diffInSeconds % 60;
    const minutes = Math.floor(diffInSeconds / 60) % 60;
    const hours = Math.floor(diffInSeconds / 3600) % 24;
    const days = Math.floor(diffInSeconds / 86400);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
  
    if (months > 0) return `${months} month${months > 1 ? 's' : ''} ago`;
    if (weeks > 0) return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
    if (days > 0) return `${days} day${days > 1 ? 's' : ''} ago`;
    if (hours > 0) return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    if (minutes > 0) return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
  };
  
    // ============ letest label on post ===============
    const isLatest = (createdAt) => {
      const now = new Date();
      const postDate = new Date(createdAt);
      const differenceInHours = (now - postDate) / (1000 * 60 * 60);
      return differenceInHours <= 24; // Post is within 24 hours
    };
  
  return (
    <div className="all-cars-container" >
    <h1 className="homecard-title bikes" id="homecard-text">All Bikes</h1>
    <div className="all-cars" style={{ marginTop: "50px" }}>
      {currentBikes.map((curEle, index) => {
        const { _id, title, imageUrl, price,  createdAt } = curEle;
        return (
          <div className="sell-all-post-child" key={index}>
            <div className="child post-container" >
              {isLatest(createdAt) && <span className="latest-label">Latest</span>}
              <img src={`https://in.quiko.in/${imageUrl[0]}`} alt="Bike" onClick={() => handleCardClick(_id)} />
              <h4 className="card-price-title">Rs {price}</h4>
              <h6>{timeAgo(createdAt)}</h6>
              {title.length > 50 ? `${title.slice(0, 50)}...` : title}
              {/* <h6>Location: {aria}</h6> */}
              <div>
                    <small >
                        <button
                          className="delete-btn btn-3"
                          onClick={() => deleteBikes(_id, imageUrl)}
                        //   onClick={() => deleteContactById(_id)}
                        >
                          delete
                        </button>
                      </small>
                      <small style={{marginLeft:"10px"}}>
                        <button
                          className="custom-btn btn-2"
                          
                          onClick={() => handleEditClick(_id)}
                        >
                          Edit
                        </button>
                      </small>
                    </div>
            </div>
          </div>
        );
      })}
    </div>

    {/* Pagination Controls */}
    <div className="pagination" style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
      {currentPage > 1 && (
        <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
          Previous
        </button>
      )}
      {getPaginationNumbers().map((page, i) => (
        <button
          key={i}
          onClick={() => typeof page === 'number' && paginate(page)}
          className={currentPage === page ? "active" : ""}
          disabled={page === '...'}
        >
          {page}
        </button>
      ))}
      {currentPage < totalPages && (
        <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>
          Next
        </button>
      )}
    </div>
  </div>
  )
}

export default BikesAdmin
