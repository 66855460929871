import React, { useState } from "react";
import { toast } from "react-toastify";

const RequestResetPassword = ({ setIsLoading }) => {
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    try { 
      const response = await fetch(
        "https://in.quiko.in/api/auth/forgot-password",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to send reset request");
      }

      const data = await response.json();
      toast.success(data.message); // Success toast
    } catch (err) {
      toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="contact-container" style={{ marginTop: "70px" }}>
      <div className="contact-content">
        <h2>Forgot Password</h2>
        <br />
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">
              Email:
            </label>
            <input
              type="email"
              name="email"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <button type="submit" className="btn btn-primary">
            Request Reset
          </button>
        </form>
      </div>
    </div>
  );
};

export default RequestResetPassword;
