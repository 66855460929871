import React from "react";
import "../Components/Contact.css";
import { Link } from "react-router-dom";

const Sell = () => {
  return (
    <div>
      <div className="contact-container">
        <div className="contact-content">
          <h1>Sell </h1>
          <p>What do you want to sell ? <b>(only 2 post in 24 Hr)</b> </p>
          <div className="container text-center">
            <div className="row row-cols-2">
              <Link
                to="/addcars"
                style={{ textDecoration: "none", color: "black" }}
              >
                <div className="col  sell-text">
                  <i className="fa-solid icon-center fa-car"></i>Cars
                </div>
              </Link>
              <Link
                to="/addcloths"
                style={{ textDecoration: "none", color: "black" }}
              >
                <div className="col  sell-text" style={{paddingTop:"30px"}}>
                <i className="fa-solid fa-shirt"></i> Cloths
                </div>
              </Link>
              <Link
                to="/addself-drives"
                style={{ textDecoration: "none", color: "black" }}
              >
                <div className="col  sell-text" style={{paddingTop:"30px"}}>
                <i className="fa-solid fa-truck-monster"></i> Self-Drive
                </div>
              </Link>
              <Link
                to="/properties"
                style={{ textDecoration: "none", color: "black" }}
              >
                <div className="col sell-text">
                  <i className="fa-solid icon-center fa-building"></i> Properties
                </div>
              </Link>
              <Link to="/addmobiles" style={{ textDecoration: "none", color: "black" }}>
                <div className="col sell-text">
                  <i className="fa-solid icon-center fa-mobile"></i> Mobiles
                </div>
              </Link>
              <Link to="/addbikes" style={{ textDecoration: "none", color: "black" }}>
                <div className="col sell-text">
                  <i className="fa-solid icon-center fa-bicycle"></i> Bikes
                </div>
              </Link>
              <Link to="/addelectronics" style={{ textDecoration: "none", color: "black" }}>
                <div className="col sell-text">
                  <i className="fa-solid icon-center fa-laptop-medical"></i> Electronics
                </div>
              </Link>
              <Link to="/addJobs" style={{ textDecoration: "none", color: "black" }}>
                <div className="col sell-text">
                  <i className="fa-solid icon-center fa-suitcase"></i> Jobs{" "}
                </div>
              </Link>
              <Link to="/addhostel" style={{ textDecoration: "none", color: "black" }}>
                {" "}
                <div className="col sell-text">
                  <i className="fa-solid icon-center fa-hotel"></i> Pg & Hostel{" "}
                </div>
              </Link>
              <Link to="/addfurniture" style={{ textDecoration: "none", color: "black" }}>
                <div className="col sell-text">
                  <i className="fa-solid icon-center fa-couch"></i> Furniture{" "}
                </div>
              </Link>
              <Link to="/addothers" style={{ textDecoration: "none", color: "black" }}>
                <div className="col sell-text" style={{paddingTop:"30px"}}>
                <i className="fa-solid fa-gear"></i> Others{" "}
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sell;
