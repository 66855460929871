import { useState, useEffect, useCallback } from "react";
import "./widgetLg.css";
import { useNavigate } from "react-router-dom";

export default function WidgetLg() {
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);

  // Fetch all posts
  const getAllPosts = useCallback(async () => {
    try {
      const response = await fetch(`https://in.quiko.in/api/sell/allposts`, {
        method: "GET",
      });
      if (response.ok) {
        const data = await response.json();
        const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format
        const todaysPosts = data.response_data.filter((post) => {
          const postDate = new Date(post.createdAt).toISOString().split("T")[0];
          return postDate === today;
        });
        setPosts(todaysPosts);
      }
    } catch (error) {
      console.error("Error fetching posts: ", error);
    }
  }, []);

  useEffect(() => {
    getAllPosts();
  }, [getAllPosts]);

  const Button = ({ type }) => {
    return <button className={"widgetLgButton " + type}>{type}</button>;
  };

  const handleCardClick = (_id, category) => {
    navigate(`/${category}/${_id}`);
  };
  return (
    <div className="widgetLg">
      <h3 className="widgetLgTitle">Today's Posts</h3>
      <table className="widgetLgTable">
        <thead>
          <tr className="widgetLgTr">
            <th className="widgetLgTh">Posts</th>
            <th className="widgetLgTh">Date</th>
            <th className="widgetLgTh">Amount</th>
            <th className="widgetLgTh">Status</th>
          </tr>
        </thead>
        <tbody>
          {posts.map((post) => (
            <tr key={post.id} className="widgetLgTr">
              <td className="widgetLgUser">
                <img
                  src={`https://in.quiko.in/${post.imageUrl[0]}`}
                  alt="User"
                  className="widgetLgImg"
                  onClick={() => handleCardClick(post._id, post.category)} 
                />
                <span className="widgetLgName">{post.title.length > 50 ? `${post.title.slice(0, 50)}...` : post.title}</span>
              </td>
              <td className="widgetLgDate">
                {new Date(post.createdAt).toLocaleDateString()}
              </td>
              <td className="widgetLgAmount">₹{post.rent || post.price || post.salaryfrom}</td>
              <td className="widgetLgStatus">
                <Button type={post.status || "Approved"} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
