import React, { useState, useEffect } from "react";
import { Button, FloatingLabel, FormControl } from "react-bootstrap";
import { toast } from "react-toastify";

function NewAdminNotificationsAll() {
  const [title, setTitle] = useState(""); // Fixed typo in setTitle
  const [body, setBody] = useState("");
  const [fcmTokens, setFcmTokens] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetch tokens on component mount
  useEffect(() => {
    const fetchTokens = async () => {
      try {
        const response = await fetch("https://in.quiko.in/api/firebase/get-all-tokens");
        if (response.ok) {
          const tokensData = await response.json();
          // console.log("Fetched Tokens:", tokensData.tokens)
          setFcmTokens(tokensData.tokens || []); // Update the state with the tokens array
        } else {
          const errorText = await response.text();
          toast.error(`Failed to fetch tokens: ${errorText}`);
        }
      } catch (error) {
        console.error("Error fetching tokens:", error);
        toast.error("Error fetching tokens");
      }
    };
  
    fetchTokens();
  }, []);
  const handlePushNotification = async (e) => {
    e.preventDefault();
    if (!title || !body) {
      toast.error("Title and Body are required");
      return;
    }
    if (fcmTokens.length === 0) {
      toast.error("No FCM tokens available");
      return;
    }

    setLoading(true);

    try {
      const data = {
        title,
        body,
        deviceTokens: fcmTokens, // Changed 'fcmTokens' to 'deviceTokens' to match API expectation
      };

      const response = await fetch("https://in.quiko.in/api/firebase/send-to-all", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        toast.success("Message sent successfully");
      } else {
        const errorText = await response.text();
        toast.error(`Failed to send notification: ${errorText}`);
        console.error("Failed to send notification", response.status, errorText);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred while sending the notification");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container firebase-form p-4">
      <h1 className="mb-5">Firebase Push Notifications</h1>
      <div className="row">
        <div className="col-md-6 mb-4">
          <FloatingLabel controlId="floatingInputs" label="Title" className="full-width">
            <FormControl
              type="text"
              placeholder="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)} // Fixed typo in setTitle
            />
          </FloatingLabel>
        </div>
        <div className="col-md-6 mb-4">
          <FloatingLabel controlId="floatingBody" label="Body" className="full-width">
            <FormControl
              type="text"
              placeholder="Body"
              value={body}
              onChange={(e) => setBody(e.target.value)}
            />
          </FloatingLabel>
        </div>
        <div className="col-md-12 mb-4">
          <Button
            variant="primary"
            size="lg"
            className="full-width"
            onClick={handlePushNotification}
            disabled={loading}
          >
            {loading ? "Sending..." : "Send"}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default NewAdminNotificationsAll;
