import { useCallback, useEffect, useState } from "react";
import "./widgetSm.css";
import { toast } from "react-toastify";
import { useAuth } from "../../../../store/auth";
import default_pic from "../../../../default-pic.png";
import { Link } from "react-router-dom";

export default function WidgetSm() {
  const [filteredData, setFilteredData] = useState([]);
  const { authorizationToken } = useAuth();

  const getAllUsersData = useCallback(async () => {
    try {
      const response = await fetch(`https://in.quiko.in/api/admin/users`, {
        method: "GET",
        headers: {
          Authorization: authorizationToken,
        },
      });
      const data = await response.json();
      if (response.ok) {
        // Sort users by creation date (latest post first)
        const sortedData = data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        // Filter users who registered today
        const today = new Date().toISOString().split("T")[0];
        const todayUsers = sortedData.filter(
          (user) => user.createdAt.split("T")[0] === today
        );

        setFilteredData(todayUsers);
      } else {
        toast.error("Failed to fetch user data.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Error fetching user data.");
    }
  }, [authorizationToken]);

  useEffect(() => {
    getAllUsersData();
  }, [getAllUsersData]);

  return (
    <div className="newadmin-widgetSm">
      <span className="newadmin-widgetSmTitle">New Join Members (Today)</span>
      <ul className="newadmin-widgetSmList">
        {filteredData.length > 0 ? (
          filteredData.slice(0, 10).map((user) => (
            <li key={user._id} className="newadmin-widgetSmListItem">
              <img
                src={`https://in.quiko.in/${user.profilePhoto}`}
                alt={user.username}
                onError={(e) => (e.target.src = default_pic)}
                className="newadmin-widgetSmImg"
              />
              <div className="newadmin-widgetSmUser">
                <span className="newadmin-widgetSmUsername">{user.username}</span>
                <span className="newadmin-widgetSmUserTitle">
                {user.email ? user.email.split("@")[0] : "N/A"}
                </span>
              </div>
              <Link to={`/newadmin/users/${user._id}`} style={{textDecoration:"none"}}>
              <button className="newadmin-widgetSmButton">
                <i className="newadmin-icons fa-solid fa-eye"></i>
              </button>
              </Link>
            </li>
          ))
        ) : (
          <li className="newadmin-widgetSmListItem">
            <div className="newadmin-widgetSmUser">
              <span style={{color:"red", fontWeight:"bold"}}>No new users registered today.</span>
            </div>
          </li>
        )}
      </ul>
    </div>
  );
}
