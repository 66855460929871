import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [user, setUser] = useState("");

  const [location, setLocation] = useState(""); // For location search
  const [searchProduct, setSearchProduct] = useState(""); // For product search

  const authorizationToken = `Bearer ${token}`;
  const API = `https://in.quiko.in/`;

  //=====================
  // jwt authentication - to get currently logedin user data
  //=====================
  const userAuthentication = useCallback(async () => {
    if (!token) return;

    setIsLoading(true);
    try {
      const response = await fetch(`https://in.quiko.in/api/auth/user`, {
        method: "GET",
        headers: {
          Authorization: authorizationToken,
        },
      });
      const data = await response.json();
      if (response.ok && data.userData) {
        setUser(data.userData);
      }
    } catch (error) {
      console.log("error fetching user data");
    } finally {
      setIsLoading(false);
    }
  }, [authorizationToken, token]);

  useEffect(() => {
    userAuthentication();
  }, [userAuthentication]);

  let isLoggedIn = !!token; // navbar.jsx mate chhe
  // tackling logout functionallty
  const LogoutUser = () => {
    setToken("");
    return localStorage.removeItem("token");
  };

  const storeTokenInLs = (serverToken) => {
    setToken(serverToken);
    return localStorage.setItem("token", serverToken);
  };

  return (
    <AuthContext.Provider
      value={{
        storeTokenInLs,
        LogoutUser,
        isLoggedIn,
        location,
        setLocation,
        searchProduct,
        setSearchProduct,
        authorizationToken,
        API,
        isLoading,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext); //akho function aek function ma
};
