import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Search from "../Assets/Search";
import SearchProduct from "../Assets/ProductSearch";
// import Arrow from "../Assets/Arrow";
import "./Navbar.css";
import logo from "../olx-logo.png";
import s_logo from "../s-logo.png";
import "../index.css";
import { ThemeContext } from "../App";
import { AuthContext, useAuth } from "../store/auth";
import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import map_img from "../map-icon.png";
import MobileNavbar from "./NavbarMobile";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";

export default function Navbar() {
  const { theme, setTheme } = useContext(ThemeContext);
  const { setLocation, setSearchProduct } = useContext(AuthContext);
  const { isLoggedIn } = useAuth();
  const [locationInput, setLocationInput] = useState("");
  const [productSearchInput, setProductSearchInput] = useState("");

  const [myOptions, setMyOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState(""); // State to store error messages
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isProductSearchVisible, setIsProductSearchVisible] = useState(false);
  // const [isProductSearchVisible, setIsProductSearchVisible] = useState(false);
  // const [isProductSearchFocused, setIsProductSearchFocused] = useState(false);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setLocation(locationInput.trim()); // Set location in context when Enter is pressed
    }
  };

  //===========prouct search ===============
  const handleProductSearchChange = (e) => {
    const inputValue = e.target.value;
    setProductSearchInput(inputValue); // Update local state

    // If input is empty, reset search
    if (inputValue.trim() === "") {
      setSearchProduct(""); // Reset product search
    }
  };
  const handleKeyPressProduct = (e) => {
    if (e.key === "Enter") {
      setSearchProduct(productSearchInput.trim()); // Trigger search on Enter
    }
  };

  const getDataFromAPI = async (event) => {
    const value = event.target.value;
    setLocationInput(value);
    setErrorMessage(""); // Clear any previous error message

    if (value.trim() === "") {
      setLocation(""); // Reset location
      setSearchProduct(""); // Reset product search to show all products
    }

    if (value.length > 2) {
      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/search?q=${value}&format=json`
        );

        if (!response.ok) {
          throw new Error("No Location Found");
        }

        const results = await response.json();
        setMyOptions(results.map((result) => result.display_name));
      } catch (error) {
        setErrorMessage("No location Found"); // Set error message if fetch fails
      }
    }
  };

  // const handleSelectSuggestion = (suggestion) => {
  //   setLocation(suggestion);
  //   setSuggestions([]);
  // };

  const handleToggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
    setIsDarkMode(!isDarkMode);
  };

  const navigate = useNavigate();
  const handleHome = () => navigate("/");
  const handleContact = () => navigate("/contact");
  const handleProfile = () => navigate("/userprofile");
  const handleLogout = () => navigate("/logout");
  const handleSignup = () => navigate("/signup");
  const handleLogin = () => navigate("/login");
  const handleSell = () => navigate("/sell");
 

  // const handleProductSearchChange = (event) => {
  //   setProductSearchInput(event.target.value);
  // };
  const handleSearchClick = () => {
    setSearchProduct(productSearchInput.trim()); // Trigger search on search icon click
  };

  const handleSearchClickLocation = (e) => {
    e.stopPropagation();
    setLocation(locationInput.trim()); // Update location on search icon click
  };

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  //-===== navbar search box show in home and other file ============================
  const showSearchBoxOn = [
    "/",
    "/featured",
    "/cars",
    "/cloths",
    "/selfdrives",
    "/bikes",
    "/jobs",
    "/mobiles",
    "/Mobiles",
    "/hostels",
    "/electronics",
    "/furniture",
    "/houses",
    "/others",
    "/admin/sell",
    "/admin",
    "/admin/contacts",
    "/admin/users",
    "/admin/featured",
    "/admin/payment",
  ];
  const location = useLocation();
  const showSearchBox = showSearchBoxOn.includes(location.pathname);

  // const handleLocationFocus = () => {
  //     setIsProductSearchVisible(true);
  // };
  // const handleProductSearchFocus = () => {
  //   setIsProductSearchFocused(true);
  // };
  
  // const handleProductSearchBlur = () => {
  //   setIsProductSearchFocused(false);
  // };
  
  // const handleLocationBlur = () => {
  //   if (document.activeElement.tagName !== 'INPUT' && productSearchInput.trim() === "") {
  //     setIsProductSearchVisible(false);
  //   }
  // };

  // Show product search on location focus
  const handleLocationFocus = () => {
    if (isMobile) setIsProductSearchVisible(true);
  };
  const handleTouchStart = () => {
    if (isMobile) {
      setIsProductSearchVisible(true);
    }
  };

  const handleOutsideClick = (e) => {
    if (
      !e.target.closest(".headerChildDiv") &&
      !e.target.closest(".locationSearch")
    ) {
      setIsProductSearchVisible(false);
    }
  };

  useEffect(() => {
    if (isMobile) {
      document.addEventListener("click", handleOutsideClick);
      return () => document.removeEventListener("click", handleOutsideClick);
    }
  }, [isMobile]);
  return (
    <>
      <div className="back">
        <div className="main">
          <div className="olxlogo" onClick={handleHome}>
            <img src={logo} alt="OLX Logo" />
          </div>

          <div className="dark-mode-toggle">
            <label className="switch">
              <input
                type="checkbox"
                checked={theme === "dark"}
                onChange={handleToggleTheme}
              />
              <span className="slider round"></span>
            </label>
          </div>

          <div className="sell-btn-container">
            <button onClick={handleSell} type="button" className="sell-btn">
              Post Free Ad
            </button>
          </div>

          <div>
            <>
              <button
                className="btn btn-primary"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRight"
                aria-controls="offcanvasRight"
              >
                <i className="fa-solid fa-bars"></i>
              </button>
              <div
                className="offcanvas offcanvas-end"
                tabIndex={-1}
                id="offcanvasRight"
                aria-labelledby="offcanvasRightLabel"
              >
                <div className="offcanvas-header">
                  <h5 className="offcanvas-title" id="offcanvasRightLabel">
                    ...
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  />
                </div>
                <div className="offcanvas-body">
                  <div className="list-group">
                    <button
                      type="button"
                      className="list-group-item list-group-item-action active"
                      aria-current="true"
                      onClick={handleHome}
                    >
                      Home
                    </button>
                    <button
                      type="button"
                      className="list-group-item list-group-item-action"
                      onClick={handleContact}
                    >
                      Contact
                    </button>
                    <a href="https://www.quiko.in/updates/" target="__blank" style={{textDecoration:"none", color:"#212529"}}>
                    <button
                      type="button"
                      className="list-group-item list-group-item-action"
                    >
                      Updates
                    </button>
                    </a>
                    {isLoggedIn ? (
                      <>
                        <button
                          type="button"
                          className="list-group-item list-group-item-action"
                          onClick={handleProfile}
                        >
                          Your Profile
                        </button>
                        <button
                          type="button"
                          className="list-group-item list-group-item-action"
                          onClick={handleLogout}
                        >
                          Logout
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          type="button"
                          className="list-group-item list-group-item-action"
                          onClick={handleSignup}
                        >
                          Sign up
                        </button>
                        <button
                          type="button"
                          className="list-group-item list-group-item-action"
                          onClick={handleLogin}
                        >
                          Login
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
        {showSearchBox && (
          <div className="up">
            <div className="headerParentDiv">
              {/* <span id="first-text">
                Free 10K+ Add Post for<span id="second-text"></span>
              </span> */}

              {/* <div className="magic-text-loader">
                <p>Free 10K+ Add Post for</p>
                <div className="magic-text-words">
                    <span className="magic-text-word">Cars & Bikes</span>
                    <span className="magic-text-word">Properties</span>
                    <span className="magic-text-word">Hostels & PGs</span>
                    <span className="magic-text-word">Mobiles & Jobs </span>
                    <span className="magic-text-word">cloths & SelfDrive</span>
                </div>
              </div> */}
              <Link to="/all-cities"  style={{textDecoration:"none"}}>
              <div className="magic-text-loader">
                <p>Find Posts in Your City {'>'} </p>
                <div className="magic-text-words">
                    <span className="magic-text-word">Ahmedabad</span>
                    <span className="magic-text-word">Bangalore</span>
                    <span className="magic-text-word">Delhi</span>
                    <span className="magic-text-word">Mumbai </span>
                    <span className="magic-text-word">Kolkata</span>

                </div>
              </div>
              </Link>

              <div className="headerChildDiv">
                <div className="brandName">
                  <img src={s_logo} alt=" Logo" style={{ height: "40px" }} />
                </div>
                {/* <div className="placeSearch">
                <Search />
                <input
                  type="text"
                  id="browser"
                  name="browser"
                  className="ali"
                  placeholder="India"
                  autoComplete="off"
                  list="browsers"
                  onChange={handleSearchChange}
                />
                <ul>
                  {suggestions.map((suggestion, index) => (
                    <li key={index} onClick={() => handleSelectSuggestion(suggestion)}>
                      {suggestion}
                    </li>
                  ))}
                </ul>

                <Arrow />
              </div> */}

                <Autocomplete
                  style={{
                    width: 350,
                    marginLeft: "10px",
                    height: 50,
                    background: "white",
                    borderRadius: 10,
                  }}
                  freeSolo
                  autoComplete
                  autoHighlight
                  options={myOptions}
                  clearOnBlur={false}
                  renderInput={(params) => (
                    <TextField
                    {...params}
                    onChange={getDataFromAPI}
                    onKeyPress={handleKeyPress}
                    onTouchStart={handleTouchStart}
                      variant="outlined"
                      // label="Search Box"
                      placeholder="Search Location Here.."
                      onFocus={handleLocationFocus}
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          height: 50,
                          borderRadius: 10,
                          border: "1px solid black",
                          fontSize: "16px",
                          fontWeight: "700",
                        },
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src="images/map-icon.png"
                              alt="Search Icon"
                              style={{ width: 26, height: 30 }}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = map_img;
                              }}
                            />
                          </InputAdornment>
                        ),
                        // Right-side search icon
                        endAdornment: (
                          <InputAdornment position="end" onClick={handleSearchClickLocation} style={{ cursor: "pointer", backgroundColor:"#89e491b0", height: "auto", borderRadius:"10px", padding:"0 3px"  }}>
                            <Search
                             className="locationSearch"
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
                {/* <div className="searchAction" >
                <p>City</p>
              </div> */}
              
                {errorMessage && (
                  <div style={{ color: "red", marginTop: "10px" }}>
                    {errorMessage}
                  </div>
                )}

{(!isMobile || isProductSearchVisible) && (
                  <div className="productSearch">
                    <div className="input">
                      <input
                        type="text"
                        placeholder="Find car, mobile phone and more..."
                        value={productSearchInput}
                        onChange={handleProductSearchChange}
                        onKeyPress={handleKeyPressProduct}
                      />
                    </div>
                    <div className="searchAction" onClick={handleSearchClick}>
                      <SearchProduct style={{ cursor: "pointer" }} onClick={handleKeyPressProduct}/>
                      {/* Search Icon */}
                    </div>
                  </div>

)}
              </div>
            </div>
          </div>
        )}
      </div>
      {isMobile && <MobileNavbar />}
    </>
  );
}
