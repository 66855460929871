import { useEffect, useState, useCallback } from "react";
import { useAuth } from "../../../../store/auth";
// import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "./Transactions.css";
import { toast } from "react-toastify";

export const NewAdminTransactions = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [razorpayPayments, setRazorpayPayments] = useState([]);
  const [usersPerPage, setUsersPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const { authorizationToken } = useAuth();

  const getAllUsersData = useCallback(async () => {
    try {
      const response = await fetch(`https://in.quiko.in/api/admin/payment`, {
        method: "GET",
        headers: {
          Authorization: authorizationToken,
        },
      });
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.log(error);
    }
  }, [authorizationToken]);

  // =================== get razor pay data =====================
  //===========================================
  const getRazorpayPayments = useCallback(async () => {
    try {
      const response = await fetch(
        `https://in.quiko.in/api/admin/razorpay-payments`,
        {
          method: "GET",
          headers: {
            Authorization: authorizationToken,
          },
        }
      );
      if (!response.ok) {
        throw new Error('Failed to fetch Razorpay payments.');
      }
      const data = await response.json();
      setRazorpayPayments(data.items || []);
      // Here you can process and display Razorpay payments in the UI
    } catch (error) {
      toast.error("Failed to fetch Razorpay payments.");
    }
  }, [authorizationToken]);

  useEffect(() => {
    getRazorpayPayments();
  }, [getRazorpayPayments]);

  const approvePost = async (postId, category) => {
    try {
      // No need to check the ID format here; just send it directly.
      const response = await fetch(
        `https://in.quiko.in/api/sell/${category}/approve/${postId}`,
        {
          method: "PUT", // Use PUT to update
          headers: {
            Authorization: authorizationToken,
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();

      if (response.ok) {
        toast.success(data.message);
        getAllUsersData(); // Refresh the user data after approval
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("An error occurred while approving the post.");
    }
  };

  
  const deletePayment = async (id) => {
    try {
      const response = await fetch(
        `https://in.quiko.in/api/admin/payment/delete/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: authorizationToken,
          },
        }
      );
      const data = await response.json();

      toast.success(data.message);
      if (response.ok) {
        getAllUsersData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllUsersData();
  }, [getAllUsersData]);

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    if (query === "") {
      setFilteredUsers(users);
    } else {
      const filteredUsers = users.filter((user) => {
        return (
          user.transactionId.toLowerCase().includes(query) ||
          user.email.toLowerCase().includes(query)
        );
      });
      setFilteredUsers(filteredUsers);
    }
  };
  useEffect(() => {
    if (searchQuery === "") {
      setFilteredUsers(users);
    }
  }, [users, searchQuery]);

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleUsersPerPage = (e) => {
    setUsersPerPage(e.target.value);
  };

  const navigate = useNavigate();
  const handleCardClick = (_id, category) => {
    navigate(`/${category}/${_id}`);
  };
  const handleEmailClick = (email) => {
    navigate(`/user-profile/${email}`);
  };

  const curUser = (user) => {
    return (
      <tr key={user._id}>
        <td>{user.transactionId}</td>
        <td
          onClick={() => handleEmailClick(user.email)}
          style={{ color: "blue", cursor: "pointer" }}
        >
          {user.email}
        </td>
        <td>{user.phoneNumber}</td>
        <td
          onClick={() => handleCardClick(user.postId, user.category)}
          style={{ color: "blue", cursor: "pointer" }}
        >
          {user.postId}
        </td>
        <td>{formatDate(user.createdAt)}</td>
        <td>
          <button
            className="premium-button"
            onClick={() => approvePost(user.postId, user.category)}
          >
            approve
          </button>
        </td>
        {/* <td><button  type="button" className="btn btn-danger" onClick={()=>deleteUser (user._id)}>Delete</button></td> */}
        <td>
          <button
            type="button"
            className="btn btn-danger"
            style={{ marginLeft: "20px" }}
            onClick={() => deletePayment(user._id)}
          >
            Delete
          </button>
        </td>
      </tr>
    );
  };

  const curRazorpayPayment = (payment) => {
    return (
      <tr key={payment.id}>
        <td>{payment.id}</td>
        <td
          onClick={() => handleEmailClick(payment.email)}
          style={{ color: "blue", cursor: "pointer" }}
        >
          {payment.email || "N/A"}
        </td>
        <td>{payment.contact || "N/A"}</td>
        {/* <td>{(payment.amount / 100).toFixed(2)} INR</td>  */}
        {/* <td>{payment.status}</td> */}
        <td
          onClick={() => handleCardClick(payment.notes.postId, payment.notes.category)}
          style={{ color: "blue", cursor: "pointer" }}
        >
          {payment.notes.postId || "N/A"}
        </td>
        <td>{formatDate(payment.created_at * 1000)}</td>
        <td>
          <button
            className="premium-button"
            onClick={() =>
              approvePost(payment.notes.postId, payment.notes.category)
            }
          >
            approve
          </button>
        </td>

      </tr>
    );
  };

  return (
    <>
      <section className="admin-users-section">
        <div className="container">
          <h1>Admin Payment Data</h1>
          <p> Total Payments From Razorpay: {razorpayPayments.length} </p>
          <p> Total Payments from Our System: {users.length} </p>
          <input
            type="search"
            value={searchQuery}
            onChange={handleSearch}
            placeholder="Search by UPI ID or email"
          />
          <select value={usersPerPage} onChange={handleUsersPerPage}>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
        </div>
        <div className="container admin-users">
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th>Transaction ID</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Post ID</th>
                <th>date & time</th>
                <th>Approval</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
               {razorpayPayments.length > 0 ? ( razorpayPayments.map(curRazorpayPayment)
            ) : (
               <tr>
                  <td colSpan="6">No Razorpay payments found.</td>
               </tr>
             )}
            {currentUsers.length > 0 ? ( currentUsers.map(curUser)
            ) : (
               <tr>
                  <td colSpan="6">No user data found.</td>
                </tr>
             )}
          </tbody>
          </table>
        </div>
        <div className="pagination">
          {currentPage > 1 && (
            <button onClick={() => paginate(currentPage - 1)}>Previous</button>
          )}
          {[...Array(totalPages).keys()].map((page, i) => (
            <button
              key={i}
              onClick={() => paginate(page + 1)}
              className={currentPage === page + 1 ? "active" : ""}
            >
              {page + 1}
            </button>
          ))}
          {currentPage < totalPages && (
            <button onClick={() => paginate(currentPage + 1)}>Next</button>
          )}
        </div>
      </section>
    </>
  );
};
