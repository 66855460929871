import React, { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../store/auth";
import "./See-All.css";
import { useNavigate } from "react-router-dom";
import default_post from "../../Assets/images/default-post.jpeg"
import {IoFilter,  IoLocationOutline } from "react-icons/io5";

function SeeAllOthers() {
  const [cars, setCars] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState({
    priceFilter: "default",
    priceRange: { from: "", to: "" },
  });
  const [tempFilters, setTempFilters] = useState({
    priceFilter: "default",
    priceRange: { from: "", to: "" },
  });
  const [showFilters, setShowFilters] = useState(false);
  const { location, searchProduct } = useContext(AuthContext);
  const [currentPage, setCurrentPage] = useState(1);
  const carsPerPage = 24; // Set to 25

  const getCars = useCallback(async () => {

    try {
      const response = await fetch(`https://in.quiko.in/api/sell/others`, {
        method: "GET",
      });
      if (response.ok) {
        const data = await response.json();
        setCars(data.response_data);
      }
    } catch (error) {
      console.log("service page error ", error);
    } 
  }, []);

  useEffect(() => {
    getCars();

    document.title = `Others Items On Quiko | Health, Education and all others etc `;
  }, [getCars]);

  const filteredCars = cars
    .filter((curEle) => {
      const lowerLocation = location.toLowerCase();
      const lowerSearchProduct = searchProduct.toLowerCase();
      return (
        ((curEle.state && curEle.state.toLowerCase().includes(lowerLocation)) ||
          (curEle.aria && curEle.aria.toLowerCase().includes(lowerLocation))) &&
        ((curEle.title &&
          curEle.title.toLowerCase().includes(lowerSearchProduct)) ||
          (curEle.brand &&
            curEle.brand.toLowerCase().includes(lowerSearchProduct)))
      );
    })
    .sort((a, b) => {
      // Sort by premium status first, then by creation date
      if (a.isPremium && !b.isPremium) return -1;
      if (!a.isPremium && b.isPremium) return 1;
      return new Date(b.createdAt) - new Date(a.createdAt);
    })
    .filter((car) => {
      if (appliedFilters.priceRange.from || appliedFilters.priceRange.to) {
        const from = parseInt(appliedFilters.priceRange.from || 0, 10);
        const to = parseInt(
          appliedFilters.priceRange.to || Number.MAX_VALUE,
          10
        );
        return car.price >= from && car.price <= to;
      }
      return true;
    })
    .sort((a, b) => {
      if (appliedFilters.priceFilter === "low-to-high") return a.price - b.price;
      if (appliedFilters.priceFilter === "high-to-low") return b.price - a.price;
      return 0;
    });


  const indexOfLastCar = currentPage * carsPerPage;
  const indexOfFirstCar = indexOfLastCar - carsPerPage;
  const currentCars = filteredCars.slice(indexOfFirstCar, indexOfLastCar);

  const totalPages = Math.ceil(filteredCars.length / carsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Pagination logic to show numbers with ellipsis when appropriate
  const getPaginationNumbers = () => {
    const pageNumbers = [];

    if (totalPages > 1) {
      if (currentPage === 1) {
        pageNumbers.push(1, 2, '...');
      } else if (currentPage === 2) {
        pageNumbers.push(1, 2, 3);
      } else if (currentPage >= 3) {
        pageNumbers.push(currentPage - 1, currentPage, '...');
      }
    } else if (totalPages === 1) {
      pageNumbers.push(1);
    }

    return pageNumbers;
  };

  const timeAgo = (dateString) => {
    const now = new Date();
    const postDate = new Date(dateString);
    const diffInSeconds = Math.floor((now - postDate) / 1000);
    const seconds = diffInSeconds % 60;
    const minutes = Math.floor(diffInSeconds / 60) % 60;
    const hours = Math.floor(diffInSeconds / 3600) % 24;
    const days = Math.floor(diffInSeconds / 86400);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);

    if (months > 0) return `${months} month${months > 1 ? 's' : ''} ago`;
    if (weeks > 0) return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
    if (days > 0) return `${days} day${days > 1 ? 's' : ''} ago`;
    if (hours > 0) return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    if (minutes > 0) return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
  };

  const isLatest = (createdAt) => {
    const now = new Date();
    const postDate = new Date(createdAt);
    const differenceInHours = (now - postDate) / (1000 * 60 * 60);
    return differenceInHours <= 24; // Post is within 24 hours
  };

  const navigate = useNavigate();
  const handleCardClick = (_id) => {
    navigate(`/others/${_id}`);
  };

  const applyFilters = () => {
    setAppliedFilters(tempFilters);
    setShowFilters(false);
  };

  return (
    <div className="all-cars-container" style={{ marginTop: "130px" }}>
      <h1 className="homecard-title bikes" id="homecard-text">Others</h1>

            {/* Price Filter Controls */}
            <div className="price-filter-container">
        <div
          className="price-filter-icon"
          onClick={() => setShowFilters(!showFilters)}
        >
          <IoFilter />
        </div>

        {/* Filter Box */}
        {showFilters && (
          <div className="price-filter-box">
            <select
              value={tempFilters.priceFilter}
              onChange={(e) =>
                setTempFilters({ ...tempFilters, priceFilter: e.target.value })
              }
            >
              <option value="default">Sort by Price</option>
              <option value="low-to-high">Low to High</option>
              <option value="high-to-low">High to Low</option>
            </select>
            <div className="price-price-range">
              <input
                type="number"
                placeholder="From"
                value={tempFilters.priceRange.from}
                onChange={(e) =>
                  setTempFilters({
                    ...tempFilters,
                    priceRange: {
                      ...tempFilters.priceRange,
                      from: e.target.value,
                    },
                  })
                }
              />
              <input
                type="number"
                placeholder="To"
                value={tempFilters.priceRange.to}
                onChange={(e) =>
                  setTempFilters({
                    ...tempFilters,
                    priceRange: {
                      ...tempFilters.priceRange,
                      to: e.target.value,
                    },
                  })
                }
              />
            </div>
            <button onClick={applyFilters}>Apply</button>
          </div>
        )}
      </div>

      <div className="all-cars" style={{ marginTop: "50px" }}>
        {currentCars.map((curEle, index) => {
          const { _id, title, imageUrl, price, aria, createdAt, isPremium } = curEle;
          return (
            <div className="sell-all-post-child" key={index}>
              <div className="child post-container" onClick={() => handleCardClick(_id)} style={ isPremium ? { border:'5px solid yellow'} : {border : 'none'} }>
                {isLatest(createdAt) && <span className="latest-label">Latest</span>}
                {isPremium && <span className="premium-badge">FEATURED</span>}
                <img src={`https://in.quiko.in/${imageUrl[0]}`} alt="Car"  onError={(e) => (e.target.src = default_post)} />
                <h4 className="card-price-title">Rs {price}</h4>
                <h6>{timeAgo(createdAt)}</h6>
                <h5>{title.length > 50 ? `${title.slice(0, 50)}...` : title}</h5>
                <h6><IoLocationOutline /> {aria.length > 40 ? `${aria.slice(0, 40)}...` : aria}
                </h6>
              </div>
            </div>
          );
        })}
      </div>

      {currentCars.length === 0 && (
        <div className="no-posts-found">
          <h2>No posts found</h2>
          <p>Try adjusting your filters to find matching results.</p>
        </div>
      )}
      
      {/* Pagination Controls */}
      <div className="pagination" style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
        {currentPage > 1 && (
          <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
            Previous
          </button>
        )}
        {getPaginationNumbers().map((page, i) => (
          <button
            key={i}
            onClick={() => typeof page === 'number' && paginate(page)}
            className={currentPage === page ? "active" : ""}
            disabled={page === '...'}
          >
            {page}
          </button>
        ))}
        {currentPage < totalPages && (
          <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>
            Next
          </button>
        )}
      </div>
    </div>
  );
}

export default SeeAllOthers;
