import React, { useState } from "react";
import { Button, FloatingLabel, FormControl } from "react-bootstrap";
import { toast } from "react-toastify";

function NewAdminNotifications() {
  const [title, setTitile] = useState("");
  const [body, setBody] = useState("");
  const [fcmToken, setFcmToken] = useState("");
  const [loading, setLoading] = useState(false);

  const handlePushNotification = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
        const data = {
            title: title,
            body: body,
            deviceToken: fcmToken,
        };

        const response = await fetch("https://in.quiko.in/api/firebase/send-notification", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });

        if (response.ok) {
            // const result = await response.json();
            // console.log(result);
            toast.success("Message sent successfully");
        } else {
            toast.error("Failed to send notification")
            // console.log("Failed to send notification", response.status);
        }
    } catch (error) {
        console.error("Error:", error);
    } finally {
        setLoading(false);
    }
};

  return (
    <>
      <div className="container firebase-form p-4">
        <h1 className="mb-5"> Firebase Push Notifications </h1>
        <div className="row">
          <div className="col-md-6 mb-4">
            <FloatingLabel
              controlId="floatingInputs"
              label="Title"
              className="full-width"
            >
              <FormControl
                type="text"
                placeholder="Title"
                value={title}
                onChange={(e) => setTitile(e.target.value)}
              />
            </FloatingLabel>
          </div>
          <div className="col-md-6 mb-4">
            <FloatingLabel
              controlId="floatingBody"
              label="Body"
              className="full-width"
            >
              <FormControl
                type="text"
                placeholder="body"
                value={body}
                onChange={(e) => setBody(e.target.value)}
              />
            </FloatingLabel>
          </div>
          <div className="col-md-12 mb-4">
            <FloatingLabel
              controlId="floatingToken"
              label="FCM Token"
              className="full-width"
            >
              <FormControl
                type="text"
                placeholder="FCM Token"
                value={fcmToken}
                onChange={(e) => setFcmToken(e.target.value)}
              />
            </FloatingLabel>
          </div>
          <div className="col-md-12">
            <Button
              variant="primary"
              size="lg"
              className="full-width"
              onClick={handlePushNotification}
              disabled={loading}
            >
              {loading ? "Sending" : "Send"}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewAdminNotifications;
