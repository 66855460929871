import "./userList.css";
import { Link } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import { useAuth } from "../../../../store/auth";
import default_pic from "../../../../default-pic.png";
import { toast } from "react-toastify";

export default function NewAdminUserList() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const [searchQuery, setSearchQuery] = useState(""); // Search query state
  const { authorizationToken } = useAuth();
  const itemsPerPage = 15;

  // Fetch all users data
  const getAllUsersData = useCallback(async () => {
    try {
      const response = await fetch(`https://in.quiko.in/api/admin/users`, {
        method: "GET",
        headers: {
          Authorization: authorizationToken,
        },
      });
      const data = await response.json();
      if (response.ok) {
        // Sort users by creation date (latest post first)
        const sortedData = data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setData(sortedData);
        setFilteredData(sortedData); // Initially set filtered data to all users
      } else {
        toast.error("Failed to fetch user data.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Error fetching user data.");
    }
  }, [authorizationToken]);

  useEffect(() => {
    getAllUsersData();
  }, [getAllUsersData]);

  // Handle delete user action
  const handleDelete = async (id) => {
    try {
      const response = await fetch(
        `https://in.quiko.in/api/admin/users/delete/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: authorizationToken,
          },
        }
      );
      const data = await response.json();
      toast.success(data.message);
      if (response.ok) {
        getAllUsersData();
      } else {
        toast.error("Failed to delete user.");
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error("Error deleting user.");
    }
  };

  // Format date and time for createdAt
  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  // Search filter functionality
  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = data.filter(
      (user) =>
        user.email.toLowerCase().includes(query) ||
        user.username.toLowerCase().includes(query)
    );
    setFilteredData(filtered);
    setCurrentPage(1); // Reset to the first page when searching
  };

  // Paginate data (get the users for the current page)
  const currentData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Handle page change
  const nextPage = () => {
    if (currentPage < Math.ceil(filteredData.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="userList">
      <input
        type="text"
        className="newadmin-user-searchBar "
        placeholder="Search by Email or Username"
        value={searchQuery}
        onChange={handleSearch}
      />
      <table className="userTable">
        <thead>
          <tr>
            <th>#</th>
            <th>User</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Created At</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentData.map((user, index) => (
            <tr key={user._id}>
              <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>{" "}
              {/* Adjust for the page */}
              <td className="userListUser" style={{ paddingTop: "22px" }}>
                <img
                  className="userListImg"
                  src={`https://in.quiko.in/${
                    user.profilePhoto || default_pic
                  }`}
                  onError={(e) => (e.target.src = default_pic)}
                  alt={`${user.username}'s profile`}
                />
                {user.username}
              </td>
              <td>{user.email}</td>
              <td>{user.phone || "N/A"}</td> {/* Show phone number */}
              <td>{formatDate(user.createdAt)}</td>{" "}
              {/* Format the created date and time */}
              <td>
                <Link to={`/newadmin/users/${user._id}`}>
                  <button className="userListEdit">Edit</button>
                </Link>
                <i
                  className="userListDelete newadmin-icons fa-solid fa-trash"
                  onClick={() => handleDelete(user._id)}
                ></i>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="newadmin-pagination">
        <button
          className="newadmin-paginationButton"
          onClick={previousPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>{`Page ${currentPage} of ${Math.ceil(
          filteredData.length / itemsPerPage
        )}`}</span>
        <button
          className="newadmin-paginationButton"
          onClick={nextPage}
          disabled={
            currentPage === Math.ceil(filteredData.length / itemsPerPage)
          }
        >
          Next
        </button>
      </div>

    </div>
    
  );
}
