import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../store/auth";
import "./HomeCard.css";
import default_post from "../Assets/images/default-post.jpeg"
import { IoLocationOutline } from "react-icons/io5";


function ScrollableCards({ setIsLoading }) {
  const scrollImagesRef = useRef(null);
  const [scrollLength, setScrollLength] = useState(0);
  const leftButtonRef = useRef(null);
  const rightButtonRef = useRef(null);

  const [car, setCar] = useState([]);
  const { location, searchProduct } = useContext(AuthContext);

  // Swipe handling
  const [touchStartX, setTouchStartX] = useState(0);
  const [touchEndX, setTouchEndX] = useState(0);

  const getCar = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`https://in.quiko.in/api/sell/cars`, {
        method: "GET",
      });
      if (response.ok) {
        const data = await response.json();
        setCar(data.response_data);
      }
    } catch (error) {
      console.log("service page error ", error);
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading]);


  useEffect(() => {
    getCar();
    const scrollImages = scrollImagesRef.current;
    const updateScrollLength = () => {
      if (scrollImages) {
        setScrollLength(scrollImages.scrollWidth - scrollImages.clientWidth);
      }
    };
    updateScrollLength();
    window.addEventListener("resize", updateScrollLength);
    return () => {
      window.removeEventListener("resize", updateScrollLength);
    };
  }, [getCar]);

  useEffect(() => {
    const scrollImages = scrollImagesRef.current;
    const leftButton = leftButtonRef.current;
    const rightButton = rightButtonRef.current;

    const checkScroll = () => {
      if (scrollImages) {
          const currentScroll = scrollImages.scrollLeft;
          if (currentScroll === 0) {
              leftButton.setAttribute("disabled", "true");
              rightButton.removeAttribute("disabled");
          } else if (currentScroll === scrollLength) {
              rightButton.setAttribute("disabled", "true");
              leftButton.removeAttribute("disabled");
          } else {
              leftButton.removeAttribute("disabled");
              rightButton.removeAttribute("disabled");
          }
      }
  };

  if (scrollImages) {
      scrollImages.addEventListener('scroll', checkScroll);
      return () => {
          scrollImages.removeEventListener('scroll', checkScroll);
      };
  }
}, [scrollLength]);

const leftScroll = () => {
  if (scrollImagesRef.current) {
      scrollImagesRef.current.scrollBy({
          left: -200,
          behavior: 'smooth'
      });
  }
};

const rightScroll = () => {
  if (scrollImagesRef.current) {
      scrollImagesRef.current.scrollBy({
          left: 200,
          behavior: 'smooth'
      });
  }
};

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStartX - touchEndX > 50) {
      // Swipe left
      rightScroll();
    }
    if (touchEndX - touchStartX > 50) {
      // Swipe right
      leftScroll();
    }
  };

  const handleTouchMove = (e) => {
    setTouchEndX(e.touches[0].clientX);
  };

  const navigate = useNavigate();
  const handleCardClick = (_id) => {
    navigate(`/car/${_id}`);
  };

  const filteredCars = car
    .filter((curEle) => {
      const lowerLocation = location.toLowerCase();
      const lowerSearchProduct = searchProduct.toLowerCase();
      return (
        ((curEle.state && curEle.state.toLowerCase().includes(lowerLocation)) ||
          (curEle.aria && curEle.aria.toLowerCase().includes(lowerLocation))) &&
        ((curEle.title &&
          curEle.title.toLowerCase().includes(lowerSearchProduct)) ||
          (curEle.brand &&
            curEle.brand.toLowerCase().includes(lowerSearchProduct)))
      );
    })
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    .slice(0, 20);

  const timeAgo = (dateString) => {
    const now = new Date();
    const postDate = new Date(dateString);
    const diffInSeconds = Math.floor((now - postDate) / 1000);

    const seconds = diffInSeconds % 60;
    const minutes = Math.floor(diffInSeconds / 60) % 60;
    const hours = Math.floor(diffInSeconds / 3600) % 24;
    const days = Math.floor(diffInSeconds / 86400);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);

    if (months > 0) return `${months} month${months > 1 ? "s" : ""} ago`;
    if (weeks > 0) return `${weeks} week${weeks > 1 ? "s" : ""} ago`;
    if (days > 0) return `${days} day${days > 1 ? "s" : ""} ago`;
    if (hours > 0) return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    if (minutes > 0) return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
  };

  const isLatest = (createdAt) => {
    const now = new Date();
    const postDate = new Date(createdAt);
    const differenceInHours = (now - postDate) / (1000 * 60 * 60);
    return differenceInHours <= 24; // Post is within 24 hours
  };

  return (
    <>
    {/* <hr/> */}
      <div className="cover">
        <div className="header-container">
          <h1 className="homecard-title bikes " id="homecard-text">
            Cars
          </h1>
          <button
            className="sell-all-btn"
            onClick={() => navigate("/cars")}
          >
            See All
          </button>
        </div>

        <button
          ref={leftButtonRef}
          className="left"
          onClick={leftScroll}
          style={{ marginLeft: "20px" }}
        >
          <i className="fas fa-angle-left"></i>
        </button>

        <div
          ref={scrollImagesRef}
          className="scroll-images"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          
          {filteredCars.map((curEle, index) => {
            const { _id, title, imageUrl, price, aria, createdAt, isPremium } = curEle;

            return (
              <div
                className="child post-container"
                key={index}

                onClick={() => handleCardClick(_id)}
                style={ isPremium ? { border:'5px solid yellow'} : {border : 'none'} }
              >
                {isLatest(createdAt) && (
                  <span className="latest-label">Latest</span>
                )}
                {isPremium && <span className="premium-badge">FEATURED</span>}
                <img src={`https://in.quiko.in/${imageUrl[0]}`} alt="Card 1" onError={(e) => (e.target.src = default_post)} />
                <h4 className="card-price-title">Rs {price}</h4>
                <h6>{timeAgo(createdAt)}</h6>
                <h5>{title.length > 50 ? `${title.slice(0, 50)}...` : title}</h5>
                <h6><IoLocationOutline /> {aria.length > 40 ? `${aria.slice(0, 40)}...` : aria}
                </h6>
              </div>
            );
          })}
        </div>

        <button ref={rightButtonRef} className="right" onClick={rightScroll}>
          <i className="fas fa-angle-right"></i>
        </button>
      </div>
      <hr/>
    </>
  );
}

export default ScrollableCards;
