import React,{useState} from "react";
import "../Components/Contact.css";
import { useAuth } from "../store/auth";
import { toast } from "react-toastify";

const Contact = ({ setIsLoading }) => {
  const [contact, setcontact] = useState({
    username: "",
    email: "",
    message: "",
  });
  // main parts is hear v
  const [userData, setUserData] = useState(true);
  const { user } = useAuth();
  if (userData && user) {
    setcontact({
      username: user.username,
      email: user.email,
      message: "",
    });
    setUserData(false);
  }

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setcontact({
      ...contact,
      [name]: value,
    });
  };
  const hanldeSubmit = async (e) => {
    e.preventDefault();
    // console.log(contact);
    setIsLoading(true)
    try {
      const Response = await fetch(`https://in.quiko.in/api/auth/contact`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(contact),
      });

      if (Response.ok) {
        setUserData(true);
        toast.success("send successfully ");
      }
    } catch (error) {
      console.log("contect", error);
      toast.error("message was not send try again");
    }finally{
      setIsLoading(false)
    }
  };

  return (
    <div>
      <div className="contact-container">
        <div className="contact-content">
          <h1>Contact Us</h1>
          <p>We'd love to hear from you!</p>
          <form onSubmit={hanldeSubmit}>
            <div className="form-group">
              <label htmlFor="name" className="form-label">Your Name</label>
              <input 
              type="text" 
              name="username"
              autoComplete="off"
              className="form-control" 
              id="name" 
              value={contact.username}
              onChange={handleInput}
              required />
            </div>
            <div className="form-group" >
              <label htmlFor="email" className="form-label">Your Email</label>
              <input
                type="email"
                name="email"
                autoComplete="off"
                className="form-control"
                id="email"
                required
                value={contact.email}
              onChange={handleInput}
              />
            </div>
            <div className="form-group">
              <label htmlFor="message" className="form-label">Message</label>
              <textarea
                className="form-control"
                id="message"
                name="message"
                cols="30"
                rows="6"
                required
                value={contact.message}
                onChange={handleInput}
              ></textarea>
            </div>
            <button type="submit" className="form-submit-button ">
              Send Message
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
