import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../olx-logo.png";
import "../Components/Navbar.css";
import { useAuth } from "../store/auth";
import { toast } from "react-toastify";
import { MdOutlineMobileFriendly } from "react-icons/md";
import { GoogleLogin } from "@react-oauth/google";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import MobileNavbar from "../Components/NavbarMobile";

const LoginForm = ({setIsLoading}) => {
  const { storeTokenInLs } = useAuth();

  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword); // Toggle the state
  };

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const navigate = useNavigate();
  function handleLogin() {
    navigate("/login");
  }
  function handleHome() {
    navigate("/");
  }
  function handleSignup() {
    navigate("/signup");
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    try {
      const Response = await fetch(`https://in.quiko.in/api/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });

      const res_data = await Response.json();
      if (Response.ok) {
        storeTokenInLs(res_data.token);
        toast.success("Login successfull");
        navigate("/");
      } else {
        // console.log(res_data);
        toast.error(
          res_data.extraDetails ? res_data.extraDetails : res_data.msg
        );
      }
    } catch (error) {
      console.log("login", error);
    }
    finally{
      setIsLoading(false)
    }
  };
  useEffect(() => {
    // Reload the page if no hash exists
    if (!window.location.hash) {
      // Set a timeout to allow React to finish rendering before reload
      const timer = setTimeout(() => {
        window.location.hash = "reloaded";
        window.location.reload();
      }, 0);

      return () => clearTimeout(timer); // Clean up the timer
    }
  }, []);

  //=============================================================
  //=================== google  login ===================
  //============================================================
  const handleGoogleSuccess = async (response) => {
    try {
      const res = await fetch(`https://in.quiko.in/api/auth/google-login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id_token: response.credential }),
      });

      const data = await res.json();

      if (res.ok) {
        // localStorage.setItem("token", data.token);
        storeTokenInLs(data.token);
        toast.success("Login successful");
        navigate("/");
 
      } else {
        toast.error(data.message || "Login failed");
      }
    } catch (error) {
      toast.error("An error occurred during login");
    }
  };

  //=============================================================
  //=================== Otp  login ===================
  //============================================================
  const [showOtpDiv, setShowOtpDiv] = useState(false); // hide div when click button
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);

  const sendOtp = async (e) => {
    e.preventDefault(); // Prevent form submission when sending OTP

    let formattedPhone = phone;
    if (!phone.startsWith("+")) {
      formattedPhone = `+${phone}`;
    }
    setIsLoading(true)
    try {
      const response = await fetch(`https://in.quiko.in/api/auth/send-otp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ phone: formattedPhone }),
      });

      const data = await response.json();
      if (response.ok) {
        setOtpSent(true);
        toast.success("OTP sent successfully");
      } else {
        toast.error(data.message || "Failed to send OTP");
      }
    } catch (error) {
      toast.error("An error occurred while sending OTP");
    }finally{
      setIsLoading(false)
    }
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    try {
      const response = await fetch(
        `https://in.quiko.in/api/auth/verify-otp`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ phone, otp }),
        }
      );

      const data = await response.json();
      if (response.ok) {
        storeTokenInLs(data.token);
        toast.success("OTP verified successfully");
        navigate("/");
      } else {
        toast.error(data.message || "Failed to verify OTP");
      }
    } catch (error) {
      toast.error("An error occurred while verifying OTP");
    }finally{
      setIsLoading(false)
    }
  };

  return (
    <div>
      <div className="back" style={{ height: "75px" }}>
        <div className="main login-signup-nav">
          <div className="olxlogo" onClick={handleHome}>
            <img src={logo} alt="OLX Logo" />
          </div>
          {/* <div className="carlogo">
            <img
              src="https://static.vecteezy.com/system/resources/previews/001/193/785/non_2x/car-png.png"
              alt="Car Logo"
            />
            <span>MOTORS</span>
          </div> */}
          {/* <div className="buildinglogo">
            <img
              src="https://freepngimg.com/save/148529-building-vector-png-download-free/512x512"
              alt="Building Logo"
            />
            <span>PROPERTY</span>
          </div> */}
          <div className=" login-btn ">
            <button
              onClick={handleLogin}
              type="button"
              // className="btn btn-primary login"
              className="log-in-btn"
            >
              Login
            </button>
            <button
              onClick={handleSignup}
              type="button"
              // className="btn btn-light login"
              className="sign-up-btn"
            >
              Sign up
            </button>
          </div>
        </div>
      </div>
      <div className="contact-container" style={{ marginTop: "70px" }}>
        <div className="contact-content">
          <h1>Login </h1>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Email address
              </label>
              <input
                type="email"
                name="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={user.email}
                onChange={handleInput}
                required
              />
              <div id="emailHelp" className="form-text">
                We'll never share your email with anyone else.
              </div>
            </div>
            <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">
            Password
          </label>
          <div className="password-container" style={{ position: "relative" }}>
            <input
              type={showPassword ? "text" : "password"} // Change input type based on visibility
              name="password"
              className="form-control"
              id="exampleInputPassword1"
              value={user.password}
              onChange={handleInput}
              required
            />
            <span
              className="toggle-password"
              onClick={togglePasswordVisibility}
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
              }}
            >
              {showPassword ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
            </span>
          </div>
        </div>
            <button type="submit" className="btn btn-primary">
              Login
            </button>

            <hr style={{ width: "100%", textAlign: "left", marginLeft: "0" }} />

            <div className="login-with-other container" >
              <div  className= "google-auth">
                <GoogleLogin
                  onSuccess={handleGoogleSuccess}
                  onError={() => toast.error("Google login failed")}
                />
              </div>

                <button
                  onClick={(e) => {
                    e.preventDefault(); // Prevents form submission
                    setShowOtpDiv(!showOtpDiv);
                  }}
                  className="sign-with-otp"
                >
                  <MdOutlineMobileFriendly />
                  {showOtpDiv ? " Login with OTP" : " Login with OTP"}
                </button>
  
            </div>

            {showOtpDiv && (
              <div style={{ marginTop: "20px" }}>
                <h2>Or Login with OTP</h2>
                <div className="mb-3">
                  <label htmlFor="phoneInput" className="form-label">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="phoneInput"
                    placeholder="Enter your number with your country code "
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                  <button
                    onClick={sendOtp}
                    style={{ marginTop: "10px" }}
                    className="btn btn-primary"
                    disabled={otpSent}
                  >
                    Send OTP
                  </button>
                </div>
                {otpSent && (
                  <div className="mb-3">
                    <label htmlFor="otpInput" className="form-label">
                      Enter OTP
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="otpInput"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                    />
                    <button
                      onClick={verifyOtp}
                      style={{ marginTop: "10px" }}
                      className="btn btn-primary"
                    >
                      Verify OTP
                    </button>
                  </div>
                )}
              </div>
            )}

            <br />
            <br />
            <Link to="/forgot-password">Forgot Password</Link>
          </form>
        </div>
      </div>
      <div>
      <footer className="footer-section">
        <div className="container">
          {/* <div className="footer-cta pt-5 pb-5">
                        <div className="row">
                            <div className="col-xl-4 col-md-4 mb-30 secondlast">
                                <div className="single-cta">
                                    <i className="fas fa-map-marker-alt"></i>
                                    <div className="cta-text">
                                        <h4>Find us</h4>
                                        <span>Motera Ahmedabad</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-4 mb-30 last">
                                <div className="single-cta">
                                    <i className="fas fa-phone"></i>
                                    <div className="cta-text">
                                        <h4>Call us</h4>
                                        <span>+91 9909552207</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-4 mb-30 end">
                                <div className="single-cta">
                                    <i className="far fa-envelope-open"></i>
                                    <div className="cta-text texting">
                                        <h4>Mail us</h4>
                                        <span>quikosell@gmail.com</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
          <div className="footer-content pt-5 pb-5">
            <div className="row">
              <div className="col-xl-4 col-lg-4 mb-50">
                <div className="footer-widget">
                  <div className="footer-logo">
                    <Link to="/">
                      <img src={logo} alt="" />
                    </Link>
                  </div>
                  <div className="footer-text">
                    <p>
                      Your trusted marketplace for buying, selling, and
                      discovering the best deals on everything you need. Join
                      millions of users and find the perfect item near you
                      today!
                    </p>
                  </div>
                  <div className="footer-social-icon">
                    <span>Follow us</span>
                    <a href="https://www.youtube.com/@quiko_now" target="__blank">
                    <i className="fa-brands fa-youtube youtube-bg"></i>
                    </a>
                    <Link to="/">
                      <i className="fab fa-twitter twitter-bg"></i>
                    </Link>
                    <a href="https://www.instagram.com/quiko_now/" target="__blank">
                       <i className="fa-brands fa-instagram insta-bg"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Our Locations</h3>
                  </div>
                  <ul>
                    <li>
                      <Link to="/login">Ahmedabad</Link>
                    </li>
                    <li>
                      <Link to="/login">Delhi</Link>
                    </li>
                    <li>
                      <Link to="/login">Mumbai</Link>
                    </li>
                    <li>
                      <Link to="/login">Bangalore</Link>
                    </li>
                    <li>
                      <Link to="/login">Vadodara</Link>
                    </li>
                    <li>
                      <Link to="/login">Hyderabad</Link>
                    </li>
                    <li>
                      <Link to="/login">Kolkata</Link>
                    </li>
                    <li>
                      <Link to="/login">Noida</Link>
                    </li>
                    <li>
                      <Link to="/login">Jaipur</Link>
                    </li>
                    <li>
                      <Link to="/login">Gandhinagar</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Subscribe</h3>
                  </div>
                  <div className="footer-text mb-25">
                    <p>
                      Don’t miss to subscribe to our new feeds, kindly fill the
                      form below.
                    </p>
                  </div>
                  <div className="subscribe-form">
                    <form action="/">
                      <input type="text" placeholder="Email Address" />
                      <button>
                        <i className="fab fa-telegram-plane"></i>
                      </button>
                    </form>
                  </div>
                  <div className="single-cta" style={{paddingTop:"40px"}}>
                    <i className="far fa-envelope-open"></i>
                    <div className="cta-text texting">
                      <h4>Mail us</h4>
                      <span>quikosell@gmail.com</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-area">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 text-center text-lg-left">
                <div className="copyright-text">
                  <p>
                    Copyright &copy; 2024, All Right Reserved{" "}
                    <Link to="/">Quiko</Link>
                  </p>
                  {/* <p style={{fontSize:"12px"}}>Designed by- <Link to="https://www.linkedin.com/in/darshan9094/" target='_blank'>Darshan</Link></p> */}
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 d-none d-lg-block text-right">
                <div className="footer-menu">
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/">Terms</Link>
                    </li>
                    <li>
                      <Link to="/">Privacy</Link>
                    </li>
                    <li>
                      <Link to="/">Policy</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      </div>
      {isMobile && <MobileNavbar />}
    </div>
  );
};

export default LoginForm;
