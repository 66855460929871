import "./productList.css";
import { Link } from "react-router-dom";


export default function NewAdminProductList() {
  // const [data, setData] = useState(productRows);

  // const handleDelete = (id) => {
  //   setData(data.filter((item) => item.id !== id));
  // };

  return (
    <div>
      <div className="contact-container">
        <div className="contact-content">
          <h1>Select Category </h1>
          <div className="container text-center">
            <div className="row row-cols-2">
              <Link
                to="/newAdmin/products/cars"
                style={{ textDecoration: "none", color: "black" }}
              >
                <div className="col  sell-text">
                  <i className="fa-solid icon-center fa-car"></i>Cars
                </div>
              </Link>
              <Link
                to="/newAdmin/products/cloths"
                style={{ textDecoration: "none", color: "black" }}
              >
                <div className="col  sell-text" style={{paddingTop:"30px"}}>
                <i className="fa-solid fa-shirt"></i> Cloths
                </div>
              </Link>
              <Link
                to="/newAdmin/products/self-drives"
                style={{ textDecoration: "none", color: "black" }}
              >
                <div className="col  sell-text" style={{paddingTop:"30px"}}>
                <i className="fa-solid fa-truck-monster"></i> Self-Drive
                </div>
              </Link>
              <Link
                to="/newAdmin/products/properties"
                style={{ textDecoration: "none", color: "black" }}
              >
                <div className="col sell-text">
                  <i className="fa-solid icon-center fa-building"></i> Properties
                </div>
              </Link>
              <Link to="/newAdmin/products/mobiles" style={{ textDecoration: "none", color: "black" }}>
                <div className="col sell-text">
                  <i className="fa-solid icon-center fa-mobile"></i> Mobiles
                </div>
              </Link>
              <Link to="/newAdmin/products/bikes" style={{ textDecoration: "none", color: "black" }}>
                <div className="col sell-text">
                  <i className="fa-solid icon-center fa-bicycle"></i> Bikes
                </div>
              </Link>
              <Link to="/newAdmin/products/electronics" style={{ textDecoration: "none", color: "black" }}>
                <div className="col sell-text">
                  <i className="fa-solid icon-center fa-laptop-medical"></i> Electronics
                </div>
              </Link>
              <Link to="/newAdmin/products/jobs" style={{ textDecoration: "none", color: "black" }}>
                <div className="col sell-text">
                  <i className="fa-solid icon-center fa-suitcase"></i> Jobs{" "}
                </div>
              </Link>
              <Link to="/newAdmin/products/hostels" style={{ textDecoration: "none", color: "black" }}>
                {" "}
                <div className="col sell-text">
                  <i className="fa-solid icon-center fa-hotel"></i> Pg & Hostel{" "}
                </div>
              </Link>
              <Link to="/newAdmin/products/furniture" style={{ textDecoration: "none", color: "black" }}>
                <div className="col sell-text">
                  <i className="fa-solid icon-center fa-couch"></i> Furniture{" "}
                </div>
              </Link>
              <Link to="/newAdmin/products/others" style={{ textDecoration: "none", color: "black" }}>
                <div className="col sell-text" style={{paddingTop:"30px"}}>
                <i className="fa-solid fa-gear"></i> Others{" "}
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
