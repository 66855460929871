
import { toast } from "react-toastify";
import "./featuredInfo.css";
import { useAuth } from "../../../../store/auth";
import { useCallback, useEffect, useState } from "react";

export default function FeaturedInfo() {

  const [totalUsers, setTotalUsers] = useState(0);
  const [usersToday, setUsersToday] = useState(0);
  const [totalPost, setTotalPost] = useState(0);
  const [postsToday, setPostsToday] = useState(0);
  const [totalFeatured, setFeatured] = useState([]);
  const { authorizationToken  } = useAuth();

  const getAllUsersData = useCallback(async () => {
    try {
      const response = await fetch(`https://in.quiko.in/api/admin/users`, {
        method: "GET",
        headers: {
          Authorization: authorizationToken,
        },
      });
      const data = await response.json();
      if (response.ok) {
        const today = new Date();
        const todayDateString = today.toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format

        const usersRegisteredToday = data.filter((user) => {
          const userDate = new Date(user.createdAt).toISOString().split("T")[0];
          return userDate === todayDateString;
        });

        setTotalUsers(data.length);
        setUsersToday(usersRegisteredToday.length);
      } else {
        toast.error("Failed to fetch user data.");
      }
    } catch (error) {
      toast.error("Error fetching user data.");
    }
  }, [authorizationToken]);

  const getAllPostsData = useCallback(async () => {
    try {
      const response = await fetch(`https://in.quiko.in/api/sell/allposts`, {
        method: "GET",
      });
      const data = await response.json();
      if (response.ok) {
        const today = new Date();
        const todayDateString = today.toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format

        const postsCreatedToday = data.response_data.filter((post) => {
          const postDate = new Date(post.createdAt).toISOString().split("T")[0];
          return postDate === todayDateString;
        });

        setTotalPost(data.response_data.length);
        setPostsToday(postsCreatedToday.length);
      } else {
        toast.error("Failed to fetch post data.");
      }
    } catch (error) {
      toast.error("Error fetching post data.");
    }
  }, []);

  const getAllFeatured = useCallback(async () => {

    try {
      const response = await fetch(`https://in.quiko.in/api/sell/premium-posts`, {
        method: "GET",
      });
      if (response.ok) {
        const data = await response.json();
        if (Array.isArray(data)) {
          setFeatured(data.length);
        } else {
          console.error("API response is not an array:", data);
          throw new Error("API response is not an array");
        }
      } else {
        throw new Error("API response is not OK");
      }
    } catch (error) {
      throw error;
    } 
  }, []);

  useEffect(() => {
    getAllUsersData();
    getAllPostsData();
    getAllFeatured();
  }, [getAllUsersData, getAllPostsData, getAllFeatured]);

  return (
    <div className="featured">
      <div className="featuredItem">
        <span className="featuredTitle">Users</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">{totalUsers}</span>
          <span className="featuredMoneyRate">
            +{usersToday || 0}
            <i className="newadmin-icons fa-solid fa-up-long"></i>
          </span>
        </div>
        <span className="featuredSub">Compared to Tommorow</span>
      </div>
      <div className="featuredItem">
        <span className="featuredTitle">Posts</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">{totalPost}</span>
          <span className="featuredMoneyRate">
            +{postsToday || 0}
            <i className="newadmin-icons fa-solid fa-up-long"></i>
          </span>
        </div>
        <span className="featuredSub">Compared to Tommorow</span>
      </div>
      <div className="featuredItem">
        <span className="featuredTitle">Featured</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">{totalFeatured}</span>
          {/* <span className="featuredMoneyRate">
            +2.4 
            <i className="newadmin-icons fa-solid fa-up-long"></i>
          </span> */}
        </div>
        <span className="featuredSub">Compared to last month</span>
      </div>
    </div>
  );
}
