import React from "react";
import "./topbar.css";


export default function NewAdminTopbar() {
  return (
    <div className="newadmin-topbar">
      <div className="newadmin-topbarWrapper">
        <div className="newadmin-topLeft">
          <span className="newadmin-logo">Admin</span>
        </div>
        <div className="newadmin-topRight">
          {/* <div className="newadmin-topbarIconContainer">
          <i className="newadmin-icons fa-solid fa-bell"></i>
            <span className="newadmin-topIconBadge">2</span>
          </div> */}
          <div className="newadmin-topbarIconContainer">
          <i className="newadmin-icons fa-solid fa-language"></i>
            <span className="newadmin-topIconBadge">2</span>
          </div>
          <div className="newadmin-topbarIconContainer">
          <i className="newadmin-icons fa-solid fa-gear"></i>
          </div>
          <img src="http://localhost:3000/static/media/olx-logo.aa23a4719d9ef7a659de.png" alt="" className="newadmin-topAvatar" />
        </div>
      </div>
    </div>
  );
}
