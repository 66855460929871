import React, { createContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { AuthProvider } from "./store/auth";
import Delivery from "./Pages/Delivery";
// import Reviews from "./Pages/Reviews";
import Contact from "./Pages/Contact";
import Sell from "./Pages/Sell";
import Home from "./Pages/Home";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import LoginForm from "./Pages/LoginForm";
import SignupForm from "./Pages/signupForm";
import ErrorPage from "./Pages/ErrorPage";
import Cars from "./Pages/Category/Cars";
import Mobile from "./Pages/Category/Mobile";
import Bikes from "./Pages/Category/Bikes";
import Electronics from "./Pages/Category/Electronics";
import Furniture from "./Pages/Category/Furniture";
import Hostels from "./Pages/Category/Hostels";
import Properties from "./Pages/Category/Properties";
import Jobs from "./Pages/Category/Jobs";
import { Logout } from "./Pages/Logout";
import SaleHouse from "./Pages/Category/Properties/SaleHouse";
import RentHouse from "./Pages/Category/Properties/RentHouse";
import Plots from "./Pages/Category/Properties/Plots";
import RentShops from "./Pages/Category/Properties/RentShops";
import SaleShops from "./Pages/Category/Properties/SaleShops";
import ProtectedRoute from "./store/ProtectedRoute";
import CardDetails from "./Card/CardDetails/CarDetails";
import BikeDetails from "./Card/CardDetails/BikeDetails";
import JobsDetails from "./Card/CardDetails/JobsDetails";
import MobileDetails from "./Card/CardDetails/MobileDetaails";
import HostelsDetails from "./Card/CardDetails/HostelsDetails";
import ElectronicDetails from "./Card/CardDetails/ElectronicDetails";
import FurnitureDetails from "./Card/CardDetails/FurnitureDetails";
import RentHouseDetails from "./Card/CardDetails/RentHouseDetails";
import SaleHouseDetails from "./Card/CardDetails/SaleHouseDetails";
import RentShopDetails from "./Card/CardDetails/RentShopDetails";
import SaleShopDetails from "./Card/CardDetails/SaleShopDetails";
import PlotsDetails from "./Card/CardDetails/PlotsDetails";
import YourPost from "./Pages/YourPost";
import { AdminLayout } from "./Pages/admin/AdminLayout";
import { AdminUser } from "./Pages/admin/admin-pages/AdminUser";
import { AdminContact } from "./Pages/admin/admin-pages/AdminContact";
import { AdminUpdate } from "./Pages/admin/admin-pages/Admin-Update";
import AdminSell from "./Pages/admin/admin-pages/Admin-Sell";
import UserProfile from "./Pages/UserProfile";
import UserProfilePosted from "./Pages/UserProfile-posted";
import Loading from "./Assets/Loading";
import EditCar from "./Components/ManagePost/EditPost/CarEdit";
import EditBike from "./Components/ManagePost/EditPost/BikeEdit";
import EditElectronics from "./Components/ManagePost/EditPost/ElectrronicsEdit";
import EditFurniture from "./Components/ManagePost/EditPost/FurnitureEdit";
import EditHostel from "./Components/ManagePost/EditPost/HostelEdit";
import EditJobs from "./Components/ManagePost/EditPost/JobsEdit";
import EditMobile from "./Components/ManagePost/EditPost/MobileEdit";
import EditPlots from "./Components/ManagePost/EditPost/PlotsEdit";
import EditRentHouse from "./Components/ManagePost/EditPost/RentHouseEdit";
import EditSaleHouse from "./Components/ManagePost/EditPost/SaleHouseEdit";
import EditRentShop from "./Components/ManagePost/EditPost/RentShopEdit";
import EditSaleShop from "./Components/ManagePost/EditPost/SaleShopEdit";
import SeeAllCars from "./Components/See-All-Post/see-all-cars";
import SeeAllBikes from "./Components/See-All-Post/See-All-Bikes";
import SeeAllJobs from "./Components/See-All-Post/See-All-Jobs";
import SeeAllMobiles from "./Components/See-All-Post/See-All-Mobiles";
import SeeAllHostels from "./Components/See-All-Post/See-All-Hostel";
import SeeAllElectronics from "./Components/See-All-Post/See-All-Electronics";
import SeeAllHouses from "./Components/See-All-Post/See-All-House";
import SeeAllFurniture from "./Components/See-All-Post/See-All-Furniture";
import Others from "./Pages/Category/Others";
import OthersDetails from "./Card/CardDetails/OthersDetails";
import EditOthers from "./Components/ManagePost/EditPost/OthersEdit";
import SeeAllOthers from "./Components/See-All-Post/See-All-Others";
import Cloths from "./Pages/Category/Cloths";
import ClothsDetails from "./Card/CardDetails/ClothsDetails";
import SelfDrives from "./Pages/Category/SelfDrives";
import SelfDrivesDetails from "./Card/CardDetails/SelfDriveDetails";
import SeeAllCloths from "./Components/See-All-Post/See-All-Cloths";
import SeeAllSelfDrives from "./Components/See-All-Post/See-All-SelfDrive";
import EditCloths from "./Components/ManagePost/EditPost/ClothsEdit";
import EditSelfDrives from "./Components/ManagePost/EditPost/SelfDrivesEdit";
import SeeAllFeatured from "./Components/See-All-Post/see-All-Featured";
import FeaturedPost from "./Pages/FeaturedPost";
import AdminFeatured from "./Pages/admin/admin-pages/Admin-Featured";
import SeeAllPosts from "./Components/See-All-Post/See-All-Posts";
import { AdminPayment } from "./Pages/admin/admin-pages/Admin-Payment";
import RefundPolicy from "./Assets/Refund-policy";
import PrivacyPolicy from "./Assets/PrivacyPolicy";
import TermsPolicy from "./Assets/TermsPolicy";
import Allcities from "./Pages/AllCities";
import NewAdminTopbar from "./Pages/NewAdmin/components/topbar/Topbar";
import NewAdminSidebar from "./Pages/NewAdmin/components/sidebar/Sidebar";
import NewAdminHome from "./Pages/NewAdmin/pages/home/Home";
import NewAdminUserList from "./Pages/NewAdmin/pages/userList/UserList";
import NewAdminUser from "./Pages/NewAdmin/pages/user/User";
import NewAdminProductList from "./Pages/NewAdmin/pages/productList/ProductList";
import NewAdminCars from "./Pages/NewAdmin/pages/productList/Cars _Admin";
import NewAdminBikes from "./Pages/NewAdmin/pages/productList/Bike_Admin";
import NewAdminCloths from "./Pages/NewAdmin/pages/productList/Cloths_Admin";
import NewAdminElectronics from "./Pages/NewAdmin/pages/productList/Electronics_Admin";
import NewAdminFurnitures from "./Pages/NewAdmin/pages/productList/Furnitures_Admin";
import NewAdminHostels from "./Pages/NewAdmin/pages/productList/Hostels_Admin";
import NewAdminHouse from "./Pages/NewAdmin/pages/productList/House_Admin";
import NewAdminJobs from "./Pages/NewAdmin/pages/productList/Jobs_Admin";
import NewAdminMobiles from "./Pages/NewAdmin/pages/productList/Mobiles_Admin";
import NewAdminOthers from "./Pages/NewAdmin/pages/productList/others_Admin";
import NewAdminSelfDrive from "./Pages/NewAdmin/pages/productList/SelfDrive_Admin";
import NewAdminMessages from "./Pages/NewAdmin/pages/Messages/Messages";
import { NewAdminTransactions } from "./Pages/NewAdmin/pages/Transactions/Transactions";
import NewAdminFeatured from "./Pages/NewAdmin/pages/Featured/Featured";
import RequestResetPassword from "./Pages/ResetPassword/RequestResetPassword";
import ResetPassword from "./Pages/ResetPassword/ResetPassword";
// import Admin from "./Pages/NewAdmin/Admin";


import { onMessageListener, requestFCMToken } from "./utils/firebaseUtils";
import { toast } from "react-toastify";
import NewAdminNotifications from "./Pages/NewAdmin/pages/Notifications/Notifications";
import NewAdminNotificationsAll from "./Pages/NewAdmin/pages/Notifications/NotifiactionAll";
// import PopupFullS from "./Assets/PopupFullS";

export const ThemeContext = createContext("null");

function App() {
  const [text, setText] = useState("Enable Dark Mode");
  const [isLoading, setIsLoading] = useState(false);
  // const [fcmToken, setFcmToken] = useState(null);
  
  function updateText() {
    setText((curr) =>
      curr === "Enable Light Mode" ? "Enable Dark Mode" : "Enable Light Mode"
    );
  }

  const [theme, setTheme] = useState("light");

  const toggleTheme = () => {
    setTheme((curr) => (curr === "light" ? "dark" : "light"));
  };

  const twoFunc = () => {
    toggleTheme();
    updateText();
  };

  //========== notifications firebase =================
  // =========================================
// Notification logic
useEffect(() => {
  // Request and save FCM token
  const fetchFCMToken = async () => {
    try {
      const token = await requestFCMToken();
      // setFcmToken(token);
      // console.log(token);

      // Save token to the server if valid
      if (token) {
        await fetch("https://in.quiko.in/api/firebase/save-token", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ token }),
        });
      }
    } catch (err) {
      console.log("Error getting FCM Token");
    }
  };

  fetchFCMToken();
}, []); // Empty dependency array ensures this runs only on mount

useEffect(() => {
  // Listen for foreground notifications
  const unsubscribe = onMessageListener()
    .then(payload => {
      toast.success(
        <div>
          <strong>{payload.notification.title}</strong>
          <div>{payload.notification.body}</div>
        </div>
      )
    })
    .catch(err => console.log("Error receiving message:"));

  // Cleanup on unmount
  return () => unsubscribe();
}, []); // Empty dependency array ensures this runs only once on mount

  //==========================================
  //===========================================

  const AppContent = () => {
    const location = useLocation();
    const hideNavbarFooter =
      location.pathname === "/login" || location.pathname === "/signup";

    return (
      <>
        {isLoading && <Loading />}

        {!hideNavbarFooter && <Navbar />}
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="flexSwitchCheckDefault"
            onClick={twoFunc}
          />
          <p className="white" onClick={updateText}>
            {text}
          </p>
        </div>

        {/* <div>
      <PopupFullS />
    </div> */}


        <Routes>
          <Route path="/" element={<Home setIsLoading={setIsLoading} />} />
          <Route
            path="/login"
            element={<LoginForm setIsLoading={setIsLoading} />}
          />
          <Route path="/logout" element={<Logout />} />
          <Route
            path="/signup"
            element={<SignupForm setIsLoading={setIsLoading} />}
          />
          <Route path="*" element={<ErrorPage />} />
          <Route path="/sell" element={<Sell />} />
          <Route
            path="/forgot-password"
            element={<RequestResetPassword setIsLoading={setIsLoading} />}
          />
          <Route
            path="/reset-password/:token"
            element={<ResetPassword setIsLoading={setIsLoading} />}
          />

          <Route
            path="/delivery"
            element={
              <ProtectedRoute>
                <Delivery />
              </ProtectedRoute>
            }
          />
          {/* <Route
            path="/reviews"
            element={
              <ProtectedRoute>
                <Reviews />
              </ProtectedRoute>
            }
          /> */}
          <Route path="/all-cities" element={<Allcities />}></Route>
          <Route
            path="/contact"
            element={
              <ProtectedRoute>
                <Contact setIsLoading={setIsLoading} />
              </ProtectedRoute>
            }
          />

          <Route
            path="/featuredpost"
            element={
              <ProtectedRoute>
                <FeaturedPost />
              </ProtectedRoute>
            }
          />
          <Route
            path="/yourpost"
            element={
              <ProtectedRoute>
                <YourPost />
              </ProtectedRoute>
            }
          />
          <Route
            path="/userprofile"
            element={
              <ProtectedRoute>
                <UserProfile setIsLoading={setIsLoading} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/user-profile/:email"
            element={
              <ProtectedRoute>
                <UserProfilePosted />
              </ProtectedRoute>
            }
          />

          <Route
            path="/addcars"
            element={
              // <ProtectedRoute>
              <Cars setIsLoading={setIsLoading} />
              // </ProtectedRoute>
            }
          />

          <Route
            path="/addcloths"
            element={
              // <ProtectedRoute>
              <Cloths setIsLoading={setIsLoading} />
              // </ProtectedRoute>
            }
          />
          <Route
            path="/addself-drives"
            element={
              // <ProtectedRoute>
              <SelfDrives setIsLoading={setIsLoading} />
              // </ProtectedRoute>
            }
          />

          <Route
            path="/addmobiles"
            element={
              // <ProtectedRoute>
              <Mobile setIsLoading={setIsLoading} />
              // </ProtectedRoute>
            }
          />
          <Route
            path="/addbikes"
            element={
              // <ProtectedRoute>
              <Bikes setIsLoading={setIsLoading} />
              // </ProtectedRoute>
            }
          />
          <Route
            path="/addelectronics"
            element={
              // <ProtectedRoute>
              <Electronics setIsLoading={setIsLoading} />
              // </ProtectedRoute>
            }
          />
          <Route
            path="/addfurniture"
            element={
              // <ProtectedRoute>
              <Furniture setIsLoading={setIsLoading} />
              // </ProtectedRoute>
            }
          />
          <Route
            path="/addhostel"
            element={
              // <ProtectedRoute>
              <Hostels setIsLoading={setIsLoading} />
              // </ProtectedRoute>
            }
          />
          <Route
            path="/properties"
            element={
              // <ProtectedRoute>
              <Properties />
              // </ProtectedRoute>
            }
          />
          <Route
            path="/addjobs"
            element={
              // <ProtectedRoute>
              <Jobs setIsLoading={setIsLoading} />
              // </ProtectedRoute>
            }
          />
          <Route
            path="/addothers"
            element={
              // <ProtectedRoute>
              <Others setIsLoading={setIsLoading} />
              // </ProtectedRoute>
            }
          />

          <Route
            path="/addsalehouse"
            element={
              // <ProtectedRoute>
              <SaleHouse setIsLoading={setIsLoading} />
              // </ProtectedRoute>
            }
          />
          <Route
            path="/addrenthouse"
            element={
              // <ProtectedRoute>
              <RentHouse setIsLoading={setIsLoading} />
              // </ProtectedRoute>
            }
          />
          <Route
            path="/addplots"
            element={
              // <ProtectedRoute>
              <Plots setIsLoading={setIsLoading} />
              // </ProtectedRoute>
            }
          />
          <Route
            path="/addrentshops"
            element={
              // <ProtectedRoute>
              <RentShops setIsLoading={setIsLoading} />
              // </ProtectedRoute>
            }
          />
          <Route
            path="/addsaleshops"
            element={
              // <ProtectedRoute>
              <SaleShops setIsLoading={setIsLoading} />
              // </ProtectedRoute>
            }
          />
          {/* ..........card detalis ........start......................... */}

          <Route path="/car/:id" element={<CardDetails />}></Route>

          <Route path="/cloths/:id" element={<ClothsDetails />}></Route>

          <Route path="/selfdrives/:id" element={<SelfDrivesDetails />}></Route>

          <Route path="/bikes/:id" element={<BikeDetails />}></Route>
          <Route path="/jobs/:id" element={<JobsDetails />}></Route>
          <Route path="/mobiles/:id" element={<MobileDetails />}></Route>
          <Route path="/hostels/:id" element={<HostelsDetails />}></Route>
          <Route
            path="/electronics/:id"
            element={<ElectronicDetails />}
          ></Route>
          <Route path="/furnitures/:id" element={<FurnitureDetails />}></Route>
          <Route path="/renthouse/:id" element={<RentHouseDetails />}></Route>
          <Route path="/salehouse/:id" element={<SaleHouseDetails />}></Route>
          <Route path="/rentshop/:id" element={<RentShopDetails />}></Route>
          <Route path="/saleshop/:id" element={<SaleShopDetails />}></Route>
          <Route path="/plots/:id" element={<PlotsDetails />}></Route>
          <Route path="/others/:id" element={<OthersDetails />}></Route>

          {/* ..........card detalis ........end......................... */}

          {/* ..........edit post  ........start......................... */}
          <Route
            path="/edit/car/:id"
            element={
              <ProtectedRoute>
                <EditCar setIsLoading={setIsLoading} />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/edit/cloths/:id"
            element={
              <ProtectedRoute>
                <EditCloths setIsLoading={setIsLoading} />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/edit/selfdrives/:id"
            element={
              <ProtectedRoute>
                <EditSelfDrives setIsLoading={setIsLoading} />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/edit/bikes/:id"
            element={
              <ProtectedRoute>
                <EditBike setIsLoading={setIsLoading} />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/edit/electronics/:id"
            element={
              <ProtectedRoute>
                <EditElectronics setIsLoading={setIsLoading} />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/edit/furnitures/:id"
            element={
              <ProtectedRoute>
                <EditFurniture setIsLoading={setIsLoading} />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/edit/hostels/:id"
            element={
              <ProtectedRoute>
                <EditHostel setIsLoading={setIsLoading} />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/edit/jobs/:id"
            element={
              <ProtectedRoute>
                <EditJobs setIsLoading={setIsLoading} />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/edit/mobiles/:id"
            element={
              <ProtectedRoute>
                <EditMobile setIsLoading={setIsLoading} />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/edit/plots/:id"
            element={
              <ProtectedRoute>
                <EditPlots setIsLoading={setIsLoading} />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/edit/renthouse/:id"
            element={
              <ProtectedRoute>
                <EditRentHouse setIsLoading={setIsLoading} />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/edit/salehouse/:id"
            element={
              <ProtectedRoute>
                <EditSaleHouse setIsLoading={setIsLoading} />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/edit/rentshop/:id"
            element={
              <ProtectedRoute>
                <EditRentShop setIsLoading={setIsLoading} />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/edit/saleshop/:id"
            element={
              <ProtectedRoute>
                <EditSaleShop setIsLoading={setIsLoading} />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/edit/others/:id"
            element={
              <ProtectedRoute>
                <EditOthers setIsLoading={setIsLoading} />
              </ProtectedRoute>
            }
          ></Route>
          {/* ..........edit post  ........end......................... */}

          {/* ..........sell all  post  ........end......................... */}
          <Route
            path="/P/:locationParam"
            element={<SeeAllPosts setIsLoading={setIsLoading} />}
          ></Route>
          <Route
            path="/P/:locationParam/:categoryParam"
            element={<SeeAllPosts setIsLoading={setIsLoading} />}
          ></Route>
          <Route path="/featured" element={<SeeAllFeatured />}></Route>
          <Route path="/cars" element={<SeeAllCars />}></Route>
          <Route path="/cloths" element={<SeeAllCloths />}></Route>
          <Route path="/selfdrives" element={<SeeAllSelfDrives />}></Route>
          <Route path="/bikes" element={<SeeAllBikes />}></Route>
          <Route path="/jobs" element={<SeeAllJobs />}></Route>
          <Route path="/mobiles" element={<SeeAllMobiles />}></Route>
          <Route path="/hostels" element={<SeeAllHostels />}></Route>
          <Route path="/electronics" element={<SeeAllElectronics />}></Route>
          <Route path="/furniture" element={<SeeAllFurniture />}></Route>
          <Route
            path="/houses"
            element={<SeeAllHouses setIsLoading={setIsLoading} />}
          ></Route>
          <Route path="/others" element={<SeeAllOthers />}></Route>

          {/* ..........see all post  ........end......................... */}

          {/* .................--- policy  --...................... */}
          <Route path="/refund" element={<RefundPolicy />}></Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route path="/terms" element={<TermsPolicy />}></Route>

          {/* <================> new admin panel <============================> */}
          {/* <Route
            path="/newadmin"
            element={
                <Admin />
            } 
          ></Route> */}

          <Route
            path="/newadmin"
            element={
              <ProtectedRoute>
                <NewAdminTopbar />
                <div style={{ display: "flex", marginTop: "10px" }}>
                  <NewAdminSidebar />
                  <NewAdminHome />
                </div>
              </ProtectedRoute>
            }
          >
            {/* <div style={{display:"flex", marginTop:"10px"}} > */}

            <Route path="users" element={<NewAdminUserList />} />
            <Route path="users/:id" element={<NewAdminUser />} />
            <Route path="messages" element={<NewAdminMessages />} />
            <Route path="notifications" element={<NewAdminNotifications />} />
            <Route path="send-all-notifications" element={<NewAdminNotificationsAll />} />
            <Route path="payment" element={<NewAdminTransactions />} />
            <Route path="featured" element={<NewAdminFeatured />} />

            <Route path="products" element={<NewAdminProductList />} />
            <Route path="products/cars" element={<NewAdminCars />} />
            <Route path="products/bikes" element={<NewAdminBikes />} />
            <Route path="products/cloths" element={<NewAdminCloths />} />
            <Route
              path="products/electronics"
              element={<NewAdminElectronics />}
            />
            <Route path="products/furniture" element={<NewAdminFurnitures />} />
            <Route path="products/hostels" element={<NewAdminHostels />} />
            <Route path="products/properties" element={<NewAdminHouse />} />
            <Route path="products/jobs" element={<NewAdminJobs />} />
            <Route path="products/mobiles" element={<NewAdminMobiles />} />
            <Route path="products/others" element={<NewAdminOthers />} />
            <Route
              path="products/self-drives"
              element={<NewAdminSelfDrive />}
            />
            {/* </div> */}
          </Route>

          {/* .................--- admin  --...................... */}
          <Route
            path="/admin"
            element={
              <ProtectedRoute>
                <AdminLayout />
              </ProtectedRoute>
            }
          >
            <Route path="users" element={<AdminUser />} />
            <Route path="users/:id/edit" element={<AdminUpdate />} />

            <Route path="contacts" element={<AdminContact />} />
            <Route path="sell" element={<AdminSell />} />
            <Route path="featured" element={<AdminFeatured />} />
            <Route path="payment" element={<AdminPayment />} />
          </Route>
        </Routes>
        {!hideNavbarFooter && <Footer />}
      </>
    );
  };

  return (
    <div id={theme}>
      <ThemeContext.Provider value={{ theme, setTheme }}>
        <AuthProvider>
          <BrowserRouter>
            <AppContent />
          </BrowserRouter>
        </AuthProvider>
      </ThemeContext.Provider>
    </div>
  );
}

export default App;
