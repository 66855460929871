import { useCallback, useEffect, useState } from "react";
import { useAuth } from "../../../../store/auth";
import "./sidebar.css";

import { Link, Navigate } from "react-router-dom";

export default function NewAdminSidebar() {

  const { authorizationToken } = useAuth();
  const [isLoading, setIsLoading] = useState(true); // Start with loading state
  const [isAdmin, setIsAdmin] = useState(null);



  const userAdmin = useCallback(async () => {
    try {
      const response = await fetch(`https://in.quiko.in/api/auth/user`, {
        method: "GET",
        headers: {
          Authorization: authorizationToken,
        },
      });
      const data = await response.json();
      if (response.ok && data.userData) {
        setIsAdmin(data.userData.isAdmin); // Update the isAdmin state

      }
    } catch (error) {
      console.log("Error fetching user data:");
    } finally {
      setIsLoading(false); // Set loading to false after data fetch
    }
  }, [authorizationToken]);

  useEffect(() => {
    userAdmin();
  }, [userAdmin]);



  if(isLoading){
    return <h6>wait ....</h6>
  }
  
  if (!isAdmin) {
    // If the user is not an admin after loading is complete
    return <Navigate to="/" />;
  }


  return (
    <div className="newadmin-sidebar">
      <div className="newadmin-sidebarWrapper">
        <div className="newadmin-sidebarMenu">
          <h3 className="newadmin-sidebarTitle">Dashboard</h3>
          <ul className="newadmin-sidebarList">
            <Link to="/newadmin" className="link">
            <li className="newadmin-sidebarListItem active">
            <i className="newadmin-icons fa-solid fa-home"></i>
              Home
            </li>
            </Link>
            {/* <li className="newadmin-sidebarListItem">
            <i className="newadmin-icons fa-solid fa-timeline"></i>
              Analytics
            </li>
            <li className="newadmin-sidebarListItem">
             <i className="newadmin-icons fa-solid fa-up-long"></i>
              Sales
            </li> */}
          </ul>
        </div>
        <div className="newadmin-sidebarMenu">
          <h3 className="newadmin-sidebarTitle">Quick Menu</h3>
          <ul className="newadmin-sidebarList">
            <Link to="/newadmin/users" className="link">
              <li className="newadmin-sidebarListItem">
              <i className="newadmin-icons fa-regular fa-user"></i>
                Users
              </li>
            </Link>
            <Link to="/newadmin/products" className="link">
              <li className="newadmin-sidebarListItem">
              <i className="newadmin-icons fa-solid fa-store"></i>
                Products
              </li>
            </Link>
            <Link to="/newadmin/payment" className="link">
            <li className="newadmin-sidebarListItem">
            <i className="newadmin-icons fa-solid fa-dollar-sign"></i>
              Transactions
            </li>
            </Link>
            <Link to="/newadmin/featured" className="link">
            <li className="newadmin-sidebarListItem">
            <i className="newadmin-icons fa-solid fa-money-bill"></i>
              Featured
            </li>
            </Link>
          </ul>
        </div>
        <div className="newadmin-sidebarMenu">
          <h3 className="newadmin-sidebarTitle">Notifications</h3>
          <ul className="newadmin-sidebarList">
            {/* <li className="newadmin-sidebarListItem">
            <i className="newadmin-icons fa-regular fa-envelope"></i>
              Mail
            </li>
            <li className="newadmin-sidebarListItem">
            <i className="newadmin-icons fa-regular fa-comments"></i>
              Feedback
            </li> */}
            <Link to="/newadmin/messages" className="link">
            <li className="newadmin-sidebarListItem">
            <i className="newadmin-icons fa-solid fa-comment"></i>
              Messages
            </li>
            </Link>
            <Link to="/newadmin/notifications" className="link">
            <li className="newadmin-sidebarListItem">
            <i className="newadmin-icons fa-regular fa-envelope"></i>
              Notifications
            </li>
            </Link>
            <Link to="/newadmin/send-all-notifications" className="link">
            <li className="newadmin-sidebarListItem">
            <i className="newadmin-icons fa-regular fa-envelope"></i>
              Notifications to all
            </li>
            </Link>
          </ul>
        </div>
        {/* <div className="newadmin-sidebarMenu">
          <h3 className="newadmin-sidebarTitle">Staff</h3>
          <ul className="newadmin-sidebarList">
            <li className="newadmin-sidebarListItem">
            <i className="newadmin-icons fa-solid fa-briefcase"></i>
              Manage
            </li>
            <li className="newadmin-sidebarListItem">
            <i className="newadmin-icons fa-solid fa-timeline"></i>
              Analytics
            </li>
            <li className="newadmin-sidebarListItem">
            <i className="newadmin-icons fa-solid fa-circle-info"></i>
              Reports
            </li>
          </ul>
        </div> */}
      </div>
    </div>
  );
}
