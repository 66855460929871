import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyD67k6BM5YxrkJXNvxQYouxGRkyiu940mY",
    authDomain: "quiko-c4fd8.firebaseapp.com",
    projectId: "quiko-c4fd8",
    storageBucket: "quiko-c4fd8.firebasestorage.app",
    messagingSenderId: "851030535731",
    appId: "1:851030535731:web:0b10d1f150a35222eb3d46"
};

const vapidKey = "BF_sknc9aa8erWt3iupyc0pO7rumsp3ZyvN35rDscPvDLdp4cEdMsfYC5g0j49C36cr2CTB9gq9UqgdHg4t1ALg"

const app = initializeApp(firebaseConfig)

const messaging = getMessaging(app)

export const requestFCMToken = async () => {
    return Notification.requestPermission()
        .then((permission) => {
            if (permission === "granted"){
                return getToken(messaging, {vapidKey})
            }else{
                throw new  Error ("notification not granted")
            }
        })
        .catch((err)=>{
            console.log("Error getting FCM token: ", err);
        })
}

export const onMessageListener = () =>{
   return new Promise((resolve) => {
        onMessage(messaging, (payload)=>{
               resolve(payload);
        })
    })
}
