import Chart from "../../components/chart/Chart";
import FeaturedInfo from "../../components/featuredInfo/FeaturedInfo";
import "./home.css";
import WidgetSm from "../../components/widgetSm/WidgetSm";
import WidgetLg from "../../components/widgetLg/WidgetLg";
import { Navigate, Outlet } from "react-router-dom";
import {  useCallback, useEffect, useState } from "react";
import { useAuth } from "../../../../store/auth";


export default function NewAdminHome() {
  const { authorizationToken } = useAuth();
  const [isLoading, setIsLoading] = useState(true); // Start with loading state
  const [isAdmin, setIsAdmin] = useState(null);
  const [data, setData] = useState([]);

  const userAdmin = useCallback(async () => {
    try {
      const response = await fetch(`https://in.quiko.in/api/auth/user`, {
        method: "GET",
        headers: {
          Authorization: authorizationToken,
        },
      });
      const data = await response.json();
      if (response.ok && data.userData) {
        setIsAdmin(data.userData.isAdmin); // Update the isAdmin state

      }
    } catch (error) {
      console.log("Error fetching user data:");
    } finally {
      setIsLoading(false); // Set loading to false after data fetch
    }
  }, [authorizationToken]);

  const getAllUsersData = useCallback(async () => {
    try {
      const response = await fetch(`https://in.quiko.in/api/admin/users-chart`, {
        method: "GET",
        headers: {
          Authorization: authorizationToken,
        },
      });
      const data = await response.json();
      if (response.ok) {
        setData(data)
      } 
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [authorizationToken]);

  useEffect(() => {
    userAdmin();
    getAllUsersData();
  }, [userAdmin,getAllUsersData]);

  if(isLoading){
    return <h1>Loading ....</h1>
  }
    
  if (!isAdmin) {
    // If the user is not an admin after loading is complete
    return <Navigate to="/" />;
  }

  return (
    <div className="newadmin-home">
      <FeaturedInfo />
      <Chart data={data} title="User Analytics" grid dataKey="userCount"/>
      <div className="newadmin-homeWidgets">
        <WidgetSm/>
        <WidgetLg/>
      </div>
      <Outlet/>
    </div>
  );
}
