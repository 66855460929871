import React from 'react'
import "./Add.css"
import { useNavigate } from 'react-router-dom'
// scrollToCategory home.js mathi 
export default function Category() {
    const navigate = useNavigate();
    const categories = [
        { id: 1, name: 'Mobile', imgSrc: 'images/mobile.gif', path: '/mobiles' },
        { id: 2, name: 'Car', imgSrc: 'images/icons8-car.gif', path: '/cars' },
        { id: 3, name: 'Property', imgSrc: 'images/property.gif', path: '/houses' },
        { id: 4, name: 'Bikes', imgSrc: 'images/bikes.gif', path: '/bikes' },
        { id: 5, name: 'Jobs', imgSrc: 'images/jobs.gif', path: '/jobs' },
        { id: 6, name: 'Hostels', imgSrc: 'images/shop.gif', path: '/hostels' },
        { id: 7, name: 'Electronics', imgSrc: 'images/electronics.gif', path: '/electronics' },
        { id: 8, name: 'Furniture', imgSrc: 'images/wood.gif', path: '/furniture' },
        { id: 9, name: 'Cloths', imgSrc: 'images/shirt.gif', path: '/cloths' },
        { id: 10, name: 'Self-Drive', imgSrc: 'images/selfdrive.gif', path: '/selfdrives' },
        { id: 11, name: 'Others', imgSrc: 'images/more.gif', path: '/others' },
      ];
    return (
        // <div>
        //     {/* <br /> */}
        //     {/* <br /> */}
        //     {/* <h1 className='category new-style-title-font' style={{ textAlign: 'center', display: "flex", justifyContent: "center" }} >ALL CATEGORIES </h1> */}
        //     {/* <br /> */}
        //     <div style={{ display: 'flex', justifyContent: "space-around", alignItems: "center", flexWrap: "wrap", textAlign: 'center' , gap:"10px"}}>

        //         <div onClick={() => navigate("/mobiles")} className='category-icons'>
        //             <img className='category-img' style={{ width: '60%' }} src="images/mobile.gif" alt="" ></img>
        //             <p className='category' style={{ fontSize:"12px", fontFamily: "serif", marginTop: "10px" }} >Mobile</p>
        //         </div>
        //         <div  onClick={() => navigate("/cars")} className='category-icons'>
        //             <img className='category-img' style={{ width: '60%' }} src="images/icons8-car.gif" alt="" ></img>
        //             <p className='category' style={{ fontSize:"12px", fontFamily: "serif", marginTop: "10px" }}> CAR </p>
        //         </div>
        //         <div onClick={() => navigate("/houses")} className='category-icons'>
        //             <img className='category-img' style={{ width: '60%' }} src="images/property.gif" alt="" ></img>
        //             <p className='category' style={{ fontSize:"12px", fontFamily: "serif", marginTop: "10px" }} >Property</p>
        //         </div>

        //         <div onClick={() => navigate("/bikes")} className='category-icons'>
        //             <img className='category-img' style={{ width: '60%' }} src="images/bikes.gif" alt=""></img>
        //             <p className='category' style={{ fontSize:"12px", fontFamily: "serif", marginTop: "10px" }} >Bikes</p>
        //         </div>

        //         <div onClick={() => navigate("/jobs")} className='category-icons'>
        //             <img className='category-img' style={{ width: '60%' }} src="images/jobs.gif" alt="" ></img>
        //             <p className='category' style={{ fontSize:"12px", fontFamily: "serif", marginTop: "10px" }} >Jobs</p>
        //         </div>

        //         <div onClick={() => navigate("/hostels")} className='category-icons' >
        //             <img className='category-img' style={{ width: '60%' }} src="images/shop.gif" alt="" ></img>
        //             <p className='category' style={{ fontSize:"12px", fontFamily: "serif", marginTop: "10px" }} >Hostels</p>
        //         </div>
        //         <div onClick={() => navigate("/electronics")}className='category-icons'>
        //             <img className='category-img' style={{ width: '60%', fontFamily: "serif", marginTop: "10px" }} src="images/electronics.gif" alt=""></img>
        //             <p className='category' style={{ fontSize:"12px", fontFamily: "serif", marginTop: "10px" }} >Electronics</p>
        //         </div>
        //         <div onClick={() => navigate("/furniture")} className='category-icons'>
        //             <img className='category-img' style={{ width: '70%' }} src="images/wood.gif" alt="" ></img>
        //             <p className='category' style={{ fontSize:"12px", fontFamily: "serif", marginTop: "10px" }} >Furniture</p>
        //         </div>

        //     </div>
           
        // </div>
        <>
        {/* <br/> */}
        <div className="horizontal-scroll-container">

      {categories.map((category) => (
        <div key={category.id} onClick={() => navigate(category.path)} className="category-icons">
          <img className="category-img" src={category.imgSrc} alt={category.name} />
          <p className="category">{category.name}</p>
        </div>
      ))}
    </div>
      </>
    )
}
