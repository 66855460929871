import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const ResetPassword = ({ setIsLoading }) => {
  const { token } = useParams(); // Get token from URL
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true)

    if (password !== confirmPassword) {
        toast.error("Passwords do not match");
      return;
    }

    try {
      const response = await fetch(`https://in.quiko.in/api/auth/reset-password/${token}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ password }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to reset password");
      }

      const data = await response.json();
      toast.success(data.message);
      navigate("/login");
    } catch (err) {
        toast.error(err.message);
    }finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="contact-container" style={{ marginTop: "70px" }}>
      <div className="contact-content">
      <h2>Reset Password</h2>
      <br/>
      <form onSubmit={handleSubmit}>
      <div className="mb-3">
              <label htmlFor="exampleInputPassword1" className="form-label">
              New Password:
              </label>
              <input
                type="password"
                className="form-control"
                id="exampleInputPassword1"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="exampleInputPassword1" className="form-label">
              Confirm Password:
              </label>
              <input
                type="password"
                className="form-control"
                id="exampleInputPassword2"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>

        <button type="submit"  className="btn btn-primary">Reset Password</button>
      </form>
    </div>
    </div>
  );
};

export default ResetPassword;
