import React, {    useState } from 'react'
import CarsAdmin from './Admin-sell/Cars _Admin'
import BikesAdmin from './Admin-sell/Bike_Admin';
import HostelsAdmin from './Admin-sell/Hostels_Admin';
import JobsAdmin from './Admin-sell/Jobs_Admin';
import MobilesAdmin from './Admin-sell/Mobiles_Admin';
import ElectronicsAdmin from './Admin-sell/Electronics_Admin';
import FurnituresAdmin from './Admin-sell/Furnitures_Admin';
import OthersAdmin from './Admin-sell/others_Admin';
import AdminHouse from './Admin-sell/House_Admin';
import ClothsAdmin from './Admin-sell/Cloths_Admin';
import SelfDriveAdmin from './Admin-sell/SelfDrive_Admin';


const AdminSell = ( ) => {


    const [totalCarsPosts, setTotalCarsPosts] = useState(0);
    const [totalClothsPosts, setTotalClothsPosts] = useState(0);
    const [totalSelfDrivePosts, setTotalSelfDrivePosts] = useState(0);
    const [totalBikesPosts, setTotalBikesPosts] = useState(0);
    const [totalHostelPosts, setTotalHostelsPosts] = useState(0);
    const [totalJobsPosts, setTotalJobsPosts] = useState(0);
    const [totalMobilePosts, setTotalMobilePosts] = useState(0);
    const [totalElectronicsPosts, setTotalElectronicsPosts] = useState(0);
    const [totalFurnituresPosts, setTotalFurnituresPosts] = useState(0);
    const [totalOthersPosts, setTotalOthersPosts] = useState(0);
    
    const [TotalRentHouse, SetTotalRentHouse] = useState(0);
    const [TotalSaleHouse, SetTotalSaleHouse] = useState(0);
    const [TotalRentShop, SetTotalRentShop] = useState(0);
    const [TotalSaleShop, SetTotalSaleShop] = useState(0);
    const [TotalPlots, SetTotalPlots] = useState(0);

    const TotalPost = totalCarsPosts+totalBikesPosts+totalClothsPosts+totalElectronicsPosts+totalFurnituresPosts+totalHostelPosts+totalJobsPosts+totalMobilePosts+totalOthersPosts+totalSelfDrivePosts+TotalRentHouse+TotalSaleHouse+TotalRentShop+TotalSaleShop+TotalPlots;


  return (
    <>
    <div className="container">
    <h1>Admin Sell Pots </h1>
    <p>Total Post {TotalPost} </p>
    <p> Total Post - Car : {totalCarsPosts}, Cloths: {totalClothsPosts}, Self-Drive: {totalSelfDrivePosts} , Bikes : {totalBikesPosts}, Hostels : {totalHostelPosts}, Jobs : {totalJobsPosts}, Mobiles : {totalMobilePosts}, Electronics : {totalElectronicsPosts}, Furnitures : {totalFurnituresPosts}, Others : {totalOthersPosts}, RentHouse : {TotalRentHouse}, SaleHouse : {TotalSaleHouse}, RentShop: {TotalRentShop}, SaleShop : {TotalSaleShop}, Plots: {TotalPlots} </p>
  </div>
    <CarsAdmin setTotalPosts={setTotalCarsPosts}/>
    <ClothsAdmin setTotalPosts={setTotalClothsPosts}/>
    <SelfDriveAdmin setTotalPosts={setTotalSelfDrivePosts}/>
    <BikesAdmin setTotalPosts={setTotalBikesPosts}/>
    <AdminHouse TotalRentHouse={SetTotalRentHouse} TotalSaleHouse={SetTotalSaleHouse} TotalRentShop={SetTotalRentShop} TotalSaleShop={SetTotalSaleShop} TotalPlots = {SetTotalPlots}/>
    <HostelsAdmin setTotalPosts={setTotalHostelsPosts}/>
    <JobsAdmin setTotalPosts={setTotalJobsPosts}/>
    <MobilesAdmin setTotalPosts={setTotalMobilePosts}/>
    <ElectronicsAdmin setTotalPosts={setTotalElectronicsPosts}/>
    <FurnituresAdmin setTotalPosts={setTotalFurnituresPosts}/>
    <OthersAdmin setTotalPosts={setTotalOthersPosts}/>
    </>
  )
}

export default AdminSell
