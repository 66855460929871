import { useState, useEffect } from "react";
import { useParams } from "react-router-dom"; // to get params and navigate
import { toast } from "react-toastify"; // assuming you're using react-toastify for toasts
import "./user.css";
import { useAuth } from "../../../../store/auth";
import default_pic from "../../../../default-pic.png";

export default function NewAdminUser() {
  const { id } = useParams(); // Get the user ID from the URL
  // const navigate = useNavigate(); 
  const [userData, setUserData] = useState({
    username: "",
    email: "",
    phone: "",
    address: "",
    profilePhoto: "",
    createdAt:"",
    lastLogin:"",
  });

  const [loading, setLoading] = useState(true); // To show a loading state while fetching data
  const { authorizationToken } = useAuth(); // Set your token here

  // Fetch user data from API on component mount
  useEffect(() => {
    fetch(`https://in.quiko.in/api/admin/users/${id}`, {
      headers: {
        Authorization: authorizationToken, // Send token for authentication
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setUserData({
          username: data.username,
          email: data.email,
          phone: data.phone,
          address: data.address ||"N/A" ,
          profilePhoto: data.profilePhoto , // Use default image if none exists
          createdAt:data.createdAt,
          lastLogin:data.lastLogin,
        });
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        setLoading(false);
      });
  }, [id, authorizationToken]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    console.log(file)
    if (file) {
      setUserData({
        ...userData,
        profilePhoto: URL.createObjectURL(file), // Update the profilePhoto to the selected image's URL
      });
    }
  };
  // Handle form submission to update user data
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      username: userData.username,
      email: userData.email,
      phone: userData.phone,
      address: userData.address,
      profileImg: userData.profileImg || "", // Send the updated profile image
    };

    try {
      const response = await fetch(
        `https://in.quiko.in/api/admin/users/update/${id}`,
        {
          method: "PATCH", // Use PATCH method to update data
          headers: {
            "Content-Type": "application/json",
            Authorization: authorizationToken, // Include the authorization token
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        toast.success("Updated successfully");
        // navigate("/newadmin/users"); 
      } else {
        toast.error("Not Updated");
      }
    } catch (error) {
      console.error("Error updating user data:", error);
    }
  };

  const formatDate = (dateString) => {
    const options = {  year: 'numeric', month: 'long',day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  // Show loading spinner while data is being fetched
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="user">
      <div className="userTitleContainer">
        <h1 className="userTitle">Edit User</h1>
      </div>
      <div className="userContainer">
        <div className="userShow">
          <div className="userShowTop">
            <img
              src={`https://in.quiko.in/${userData.profilePhoto }` }
              alt="User"
              className="userShowImg"
              onError={(e) => (e.target.src = default_pic)}
            />
            <div className="userShowTopTitle">
              <span className="userShowUsername">{userData.username}</span>
              <span className="userShowUserTitle">{userData.email}</span>
            </div>
          </div>
          <div className="userShowBottom">
            <span className="userShowTitle">Account Details</span>
            <div className="userShowInfo">
              <i className="userUpdateIcon newadmin-icons fa-solid fa-user"></i>
              <span className="userShowInfoTitle">{userData.username}</span>
            </div>
            <div className="userShowInfo">
              <i className="userUpdateIcon newadmin-icons fa-solid fa-user-plus"></i>
              <span className="userShowInfoTitle">{formatDate(userData.createdAt)}</span>
            </div>
            <div className="userShowInfo">
              <i className="userUpdateIcon newadmin-icons fa-solid fa-right-to-bracket"></i>
              <span className="userShowInfoTitle">{formatDate(userData.lastLogin)}</span>
            </div>
            <span className="userShowTitle">Contact Details</span>
            <div className="userShowInfo">
              <i className="userUpdateIcon newadmin-icons fa-solid fa-mobile"></i>
              <span className="userShowInfoTitle">{userData.phone}</span>
            </div>
            <div className="userShowInfo">
              <i className="userUpdateIcon newadmin-icons fa-regular fa-envelope"></i>
              <span className="userShowInfoTitle">{userData.email}</span>
            </div>
            <div className="userShowInfo">
              <i className="userUpdateIcon newadmin-icons fa-solid fa-location-dot"></i>
              <span className="userShowInfoTitle">{userData.address}</span>
            </div>
          </div>
        </div>
        <div className="userUpdate">
          <span className="userUpdateTitle">Edit</span>
          <form className="userUpdateForm" onSubmit={handleSubmit}>
            <div className="userUpdateLeft">
              <div className="userUpdateItem">
                <label>Username</label>
                <input
                  type="text"
                  name="username"
                  value={userData.username}
                  onChange={handleChange}
                  className="userUpdateInput"
                />
              </div>

              <div className="userUpdateItem">
                <label>Email</label>
                <input
                  type="text"
                  name="email"
                  value={userData.email}
                  onChange={handleChange}
                  className="userUpdateInput"
                />
              </div>
              <div className="userUpdateItem">
                <label>Phone</label>
                <input
                  type="text"
                  name="phone"
                  value={userData.phone}
                  onChange={handleChange}
                  className="userUpdateInput"
                />
              </div>
              <div className="userUpdateItem">
                <label>Address</label>
                <input
                  type="text"
                  name="address"
                  value={userData.address}
                  onChange={handleChange}
                  className="userUpdateInput"
                />
              </div>
            </div>
            <div className="userUpdateRight">
              <div className="userUpdateUpload">
                <img
                  className="userUpdateImg"
                  src={`https://in.quiko.in/${userData.profilePhoto || default_pic}`}
                  alt="Profile"
                  onError={(e) => (e.target.src = default_pic)}
                />
                <label htmlFor="file">
                  <i className="userUpdateIcon newadmin-icons fa-solid fa-upload"></i>
                </label>
                <input
                  type="file"
                  id="file"
                  style={{ display: "none" }}
                  onChange={handleImageUpload}
                />
              </div>
              <button className="userUpdateButton" type="submit">Update</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
