import React from 'react'
import banner from "../Assets/bannersvg.svg"

export default function BannerAdd() {
    return (
        <div>
            <a href='https://play.google.com/store/apps/details?id=com.quikosell.QuikoApp&pcampaignid=web_share' target='__blank'><img className='banner' src={banner} alt="Banner"  /></a>
        </div>
    )
}
