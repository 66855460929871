import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaSearchLocation } from "react-icons/fa";

const MobileNavbar = () => {
  const [isCategoryBoxVisible, setCategoryBoxVisible] = useState(false);
  const navigate = useNavigate();

  const handleHome = () => navigate('/');
  const handleProfile = () => navigate('/userprofile');
  const handleSell = () => setCategoryBoxVisible(!isCategoryBoxVisible);
  const handleYourPost = () => navigate('/yourpost');
  const handleCity = () => navigate('/all-cities');

  // Array of categories with titles, paths, and icons
  const categories = [
    { title: 'Cars', path: '/addcars', icon: 'fa-solid fa-car' },
    { title: 'Cloths', path: '/addcloths', icon: 'fa-solid fa-shirt' },
    { title: 'Mobiles', path: '/addmobiles', icon: 'fa-solid fa-mobile-alt' },
    { title: 'Bikes', path: '/addbikes', icon: 'fa-solid icon-center fa-bicycle' },
    { title: 'Jobs', path: '/addjobs', icon: 'fa-solid fa-briefcase' },
    { title: 'Electronics', path: '/addelectronics', icon: 'fa-solid fa-tv' },
    { title: 'Pg & Hostel', path: '/addhostel', icon: 'fa-solid icon-center fa-hotel' },
    { title: 'Others', path: '/addothers', icon: 'fa-solid fa-gear' },
    { title: 'Furniture', path: '/addfurniture', icon: 'fa-solid fa-couch' },
    { title: 'Self-Drives', path: '/addself-drives', icon: 'fa-solid fa-truck-monster' },
    { title: 'Sale House', path: '/addsalehouse', icon: 'fa-solid fa-money-bill' },
    { title: 'Rent House', path: '/addrenthouse', icon: 'fa-solid fa-building' },
    { title: 'Rent Shop', path: '/addrentshops', icon: 'fa-solid fa-money-bill' },
    { title: 'Sale Shop', path: '/addsaleshops', icon: 'fa-solid fa-shop' },
    { title: ' Lands & plots', path: '/addplots', icon: 'fa-solid fa-house' },
  ];

  const handleCategoryClick = (path) => {
    navigate(path); 
    setCategoryBoxVisible(false);
  };

  return (
    <div>
      {/* Category box at the top center */}
      {isCategoryBoxVisible && (
        <div className="category-box">
          <div className="category-column left-column">
            {categories.slice(0, 9).map((category, index) => (
              <div
                key={index}
                onClick={() => handleCategoryClick(category.path)}
                className="category-item"
              >
                <i className={category.icon}></i>
                <span style={{fontSize:"20px", fontWeight:"600"}}>{category.title}</span>
              </div>
            ))}
          </div>
          <div className="category-column right-column">
            {categories.slice(9).map((category, index) => (
              <div
                key={index}
                onClick={() => handleCategoryClick(category.path)}
                className="category-item"
              >
                <i className={category.icon}></i>
                <span style={{fontSize:"20px", fontWeight:"600"}}>{category.title}</span>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Navbar fixed at the bottom */}
      <div className="mobile-navbar">
      <ul>
          <li onClick={handleHome}>
            <div className="icon-text">
              <i className="fa-solid fa-house"></i>
              <span>Home</span>
            </div>
          </li>
          <li onClick={handleCity}>
            <div className="icon-text">
              <FaSearchLocation style={{ fontSize: "24px" ,color:"black"}} />
              <span>City</span>
            </div>
          </li>
          <li onClick={handleSell} className="sell-button">
            {/* <div className="icon-text"> */}
              <i className="fa-solid fa-plus"></i>
              {/* <span style={{color:"black"}}>Sell</span> */}
            {/* </div> */}
          </li>
          <li onClick={handleYourPost} style={{marginLeft:"80px"}}>
            <div className="icon-text">
              <i className="fa-solid fa-comments"></i>
              <span>Posts</span>
            </div>
          </li>
          <li onClick={handleProfile}>
            <div className="icon-text">
              <i className="fa-solid fa-user"></i>
              <span>Profile</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MobileNavbar;
